@import url('https://fonts.googleapis.com/css?family=Nunito:300,400,600,700&display=swap');
@import url('https://fonts.googleapis.com/css?family=Lato:300,400,700&display=swap');
@font-face {
  font-family: 'PalatinoLinotype-Roman';
  src: url('../fonts/PalatinoLinotype-Roman.eot?#iefix')
      format('embedded-opentype'),
    url('../fonts/PalatinoLinotype-Roman.woff') format('woff'),
    url('../fonts/PalatinoLinotype-Roman.ttf') format('truetype'),
    url('../fonts/PalatinoLinotype-Roman.svg#PalatinoLinotype-Roman')
      format('svg');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'PalatinoLinotype-Bold';
  src: url('../fonts/PalatinoLinotype-Bold.eot?#iefix')
      format('embedded-opentype'),
    url('../fonts/PalatinoLinotype-Bold.woff') format('woff'),
    url('../fonts/PalatinoLinotype-Bold.ttf') format('truetype'),
    url('../fonts/PalatinoLinotype-Bold.svg#PalatinoLinotype-Bold')
      format('svg');
  font-weight: normal;
  font-style: normal;
}

img {
  max-width: 100%;
}

body {
  background-color: #e0e5e6;
  font-family: 'Nunito', sans-serif;
}

.gray-bg {
  background-color: #f6f7f9 !important;
}

.form-group {
  position: relative;
}

textarea {
  resize: none;
}

#wrapper {
  height: 100%;
  overflow-y: auto;
}

.coman-modal-a {
  height: 100%;
  align-items: center;
  display: flex;
  justify-content: center;
}

.btn-primary {
  /* background-color: #0000ff; */
  background-color: #404e67;
  border-color: transparent;
  font-size: 16px;
  color: #ffffff;
  border: none;
  padding: 8px 36px;
  border-radius: 3px;
  /* border: 1px solid #0000ff; */
}

.btn-primary.btn-small {
  padding: 8px 37px;
}

.btn {
  padding: 8px 29px;
  font-size: 13px !important;
  font-weight: bold;
}

.btn-default {
  background-color: #0000ff;
  border-color: #0000ff;
  font-size: 16px;
  color: #fff;
  border-radius: 3px;
}

.btn-default:hover,
.btn-default:focus,
.btn-default.active {
  border-color: #0000ff;
  color: #0000ff;
  background: #fff;
}

.btn-dark {
  background-color: #0c151e;
  color: #0000ff;
}

button:focus {
  outline: none;
}

.header-page {
  padding: 20px 100px 20px 100px;
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  z-index: 4;
}

.logo {
  float: left;
}

.logo a {
  display: inline-block;
  vertical-align: top;
}

.top-right-header {
  float: right;
  padding: 10px 0 0 0;
}

.login-btn {
  float: right;
}

.login-btn a {
  font-size: 16px;
  color: #ffffff;
  padding: 7px 25px;
  border-radius: 5px;
}

.header-page .top-menu {
  float: left;
  padding: 10px 0 0 0;
}

.header-page .top-menu ul {
  margin: 0;
  padding: 0;
}

.header-page .top-menu ul li {
  margin: 0;
  padding: 0 40px 9px 0;
  list-style: none;
  float: left;
  position: relative;
}

.header-page .top-menu ul li a {
  font-size: 16px;
  color: #ffffff;
  font-weight: normal;
  margin: 0;
  padding: 0;
  background: none;
}

.header-page .top-menu ul li a:hover {
  color: #0000ff;
}

.header-page .top-menu ul li a.active {
  color: #0000ff;
}

.header-page .top-menu ul li ul {
  position: absolute;
  left: 0;
  top: 100%;
  background: rgba(0, 0, 0, 0.6);
  padding: 15px 20px;
  min-width: 135px;
  display: none;
}

.header-page .top-menu ul li ul li {
  padding: 5px 0;
}

.header-page .top-menu ul li:hover ul {
  display: block;
}

.banner-section {
  display: table;
  height: 100%;
  width: 100%;
  overflow: hidden;
}

.banner-slider {
  display: table-row;
  height: 100%;
}

.banner-box > img {
  width: 100%;
  height: 100vh;
}

.banner-box .container {
  position: relative;
}

.banner-box .text {
  width: 550px;
  background: #ffffff;
  padding: 50px 50px;
  position: absolute;
  left: 15px;
  top: 200px;
}

.banner-box .text:before {
  position: absolute;
  left: -10px;
  top: -10px;
  height: 10px;
  width: 100%;
  content: '';
  background: #0000ff;
  z-index: 1;
}

.banner-box .text:after {
  position: absolute;
  left: -10px;
  top: -10px;
  height: 100%;
  width: 10px;
  content: '';
  background: #0000ff;
  z-index: 1;
}

.banner-box .text h2 {
  font-size: 60px;
  color: #000000;
  font-weight: normal;
  margin: 0;
  padding: 0 0 30px 0;
  font-family: 'PalatinoLinotype-Roman';
}

.banner-box .text p {
  font-size: 22px;
  color: #000000;
  font-weight: normal;
  line-height: 36px;
  margin: 0;
  padding: 0 0 0px 0;
}

.banner-slider .carousel-control {
  width: 50px;
  height: 50px;
  border-radius: 0;
  display: inline-block;
  opacity: 1;
  top: 50%;
  margin-top: -25px;
}

.banner-slider .carousel-control:hover {
  background-color: #0000ff;
}

.banner-slider .carousel-indicators li {
  width: 14px;
  height: 14px;
  border-radius: 50%;
  background: #ffffff;
  margin: 0 5px;
  padding: 0;
  border: none;
}

.banner-slider .carousel-indicators li.active {
  background: #0000ff;
}

/* Carousel Fading slide */

.carousel-fade .carousel-inner {
  background: #000;
}

.carousel-fade .carousel-control {
  z-index: 2;
}

.carousel-fade .item {
  opacity: 0;
  -webkit-transition-property: opacity;
  -moz-transition-property: opacity;
  -o-transition-property: opacity;
  transition-property: opacity;
}

.carousel-fade .next.left,
.carousel-fade .prev.right,
.carousel-fade .item.active {
  opacity: 1;
}

.carousel-fade .active.left,
.carousel-fade .active.right {
  left: 0;
  opacity: 0;
  z-index: 1;
}

/* Safari Fix */

@media all and (transform-3d), (-webkit-transform-3d) {
  .carousel-fade .carousel-inner > .item.next,
  .carousel-fade .carousel-inner > .item.active.right {
    opacity: 0;
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }
  .carousel-fade .carousel-inner > .item.prev,
  .carousel-fade .carousel-inner > .item.active.left {
    opacity: 0;
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }
  .carousel-fade .carousel-inner > .item.next.left,
  .carousel-fade .carousel-inner > .item.prev.right,
  .carousel-fade .carousel-inner > .item.active {
    opacity: 1;
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }
}

.newly-top-form {
  background: rgba(0, 0, 0, 0.4);
  padding: 30px 30px 0 30px;
  margin-bottom: 90px;
}

.newly-top-form .form-control {
  font-size: 16px;
  color: #ffffff;
  font-weight: normal;
  background: url(../img/select-box-arrow.png) right center no-repeat
    rgba(0, 0, 0, 0.4);
  border: 1px solid #c3bcbc;
  border-radius: 6px;
  height: 42px;
  -moz-appearance: none;
  appearance: none;
  -webkit-appearance: none;
  -ms-appearance: none;
  -o-appearance: none;
}

.newly-top-form select option {
  background: rgba(0, 0, 0, 0.95);
}

.newly-top-form .form-group {
  margin-bottom: 30px;
}

.newly-top-form .form-group label {
  font-size: 16px;
  color: #ffffff;
  font-weight: normal;
  display: block;
}

.newly-top-form .form-group label strong {
  font-weight: bold;
  float: right;
}

.newly-top-form .btn {
  height: 42px;
  padding: 8px 37px;
}

.title-def {
  position: relative;
  padding: 0 0 18px 0;
  margin-bottom: 50px;
  text-align: center;
}

.title-def:before {
  position: absolute;
  left: 50%;
  bottom: 0;
  content: '';
  width: 60px;
  height: 4px;
  background: #0000ff;
  margin-left: -30px;
}

.title-def h2 {
  font-size: 50px;
  color: #ffffff;
  font-weight: normal;
  margin: 0;
  padding: 0;
  font-family: 'PalatinoLinotype-Bold';
}

.newly-list .box {
  margin-bottom: 30px;
}

.newly-list .box .image {
  position: relative;
}

.newly-list .box .image img {
  width: 100%;
}

.newly-list .box .image .soon {
  position: absolute;
  top: 20px;
  right: -10px;
}

.newly-list .box .image .soon:before {
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 7px 0 0 10px;
  border-color: transparent transparent transparent #019477;
  position: absolute;
  right: 0;
  top: -7px;
  content: '';
}

.newly-list .box .image .soon h4 {
  font-size: 16px;
  color: #ffffff;
  font-weight: normal;
  margin: 0;
  background: #0000ff;
  padding: 7px 15px;
}

.newly-list .box .image .rental-label {
  top: 20px;
  left: 20px;
  right: auto;
}

.newly-list .box .image .rental-label:before {
  display: none;
}

.newly-list .box .image .rental-label h4 {
  color: #ffffff;
  background: #000000;
}

.newly-list .box .image .price {
  position: absolute;
  left: 0;
  bottom: 0;
  right: 0;
  padding: 0px 20px 32px 20px;
}

.newly-list .box .image .price h3 {
  font-size: 14px;
  color: #ffffff;
  font-weight: normal;
  margin: 0;
  padding: 0;
}

.newly-list .box .image .price h3 strong {
  font-size: 30px;
  color: #ffffff;
  font-weight: bold;
  display: block;
  padding: 0 0 6px 0;
}

.newly-list .box .text {
  padding: 19px 19px 19px 19px;
  border: 1px solid #dedede;
  border-top: 0;
  background: #ffffff;
}

.newly-list .box .text h3 {
  font-size: 18px;
  color: #000000;
  font-weight: bold;
  margin: 0;
  padding: 0 0 8px 0;
}

.newly-list .box .text h3 a {
  color: #000000;
}

.newly-list .box .text h3 a:hover {
  color: #0000ff;
}

.newly-list .box .text p {
  font-size: 14px;
  line-height: 22px;
  color: #888888;
  font-weight: normal;
  margin: 0;
  padding: 0;
}

.newly-list .box .text h5 {
  font-size: 14px;
  color: #000000;
  font-weight: normal;
  margin: 0;
  padding: 12px 0 0 0;
}

.dtls {
  border-top: 1px solid #dedede;
  border-bottom: 1px solid #dedede;
  margin: 15px 0 0 0;
  padding: 10px 0;
}

.newly-list .box .text .newly-info {
  padding: 15px 0 0 0;
}

.newly-list .box .text .newly-info h5 {
  display: inline-block;
  vertical-align: middle;
  margin: 0;
  padding: 0 23px 0 0;
  line-height: 20px;
}

.newly-list .box .text .newly-info h5:last-child {
  padding: 0;
}

.newly-list .box .text .newly-info h5 img {
  vertical-align: top;
}

.footer-page {
  background: #000000;
  padding: 29px 0px;
  border-top: 1px solid #1a1a1a;
}

.foot-menu {
  float: left;
}

.foot-menu ul {
  margin: 0;
  padding: 0;
}

.foot-menu ul li {
  margin: 0;
  padding: 0 20px;
  list-style: none;
  float: left;
  line-height: 14px;
  border-left: 1px solid #ffffff;
}

.foot-menu ul li a {
  margin: 0;
  padding: 0;
  font-size: 16px;
  line-height: 14px;
  color: #ffffff;
  font-weight: normal;
}

.foot-menu ul li a:hover {
  color: #0000ff;
}

.foot-menu ul li.active a {
  color: #0000ff;
}

.foot-menu ul li:first-child {
  padding-left: 0;
  border-left: 0;
}

.copy-right {
  float: right;
}

.copy-right p {
  font-size: 16px;
  line-height: 14px;
  color: #ffffff;
  font-weight: normal;
  margin: 0;
  padding: 0;
}

.properties-details-sliders {
  padding: 0 0 0 0;
  margin: 6px 0 0 0;
  position: relative;
  overflow: hidden;
}

.properties-details-sliders img {
  width: 100%;
}

.properties-details-sliders .slick-slide {
  padding: 0 15px;
}

.properties-details-sliders::before {
  position: absolute;
  top: 0;
  right: -15px;
  height: 100%;
  width: 22%;
  content: '';
  background: rgba(0, 0, 0, 0.7);
  z-index: 9;
}

.properties-details-sliders::after {
  position: absolute;
  top: 0;
  left: -15px;
  height: 100%;
  width: 22%;
  content: '';
  background: rgba(0, 0, 0, 0.7);
  z-index: 9;
}

.properties-details-sliders .slick-arrow:before {
  display: none;
}

.properties-details-sliders .slick-prev:hover {
  background-color: #0000ff;
}

.properties-details-sliders .slick-next:hover {
  background-color: #0000ff;
}

.property-dtl-top-section {
  padding: 85px 0 0 0;
}

.property-dtl-top-section h2 {
  font-size: 30px;
  color: #ffffff;
  font-weight: bold;
  margin: 0;
  padding: 0 0 14px 0;
  font-family: 'Lato', sans-serif;
}

.property-dtl-top-section p {
  font-size: 18px;
  color: #888888;
  font-weight: normal;
  margin: 0;
  padding: 0;
}

.property-dtl-top-section h3 {
  font-size: 36px;
  color: #0000ff;
  font-weight: bold;
  margin: 0;
  padding: 0 0 22px 0;
  font-family: 'Lato', sans-serif;
}

.dl-left {
  float: left;
}

.dl-right {
  float: right;
}

.land-info-list {
  clear: both;
  overflow: hidden;
  padding: 10px 0 0 0;
}

.land-info-list ul {
  margin: 0 0 0 -30px;
  padding: 0;
}

.land-info-list ul li {
  margin: 0;
  padding: 0 0 30px 30px;
  list-style: none;
  float: left;
}

.land-info-list ul li .box {
  border: 1px solid #dedede;
  border-radius: 5px;
  text-align: center;
  padding: 16px 10px 15px 10px;
  font-size: 18px;
  color: #ffffff;
  font-weight: normal;
  width: 180px;
}

.land-info-list ul li .box img {
  display: inline-block;
  vertical-align: middle;
  margin-right: 5px;
}

.land-info-list ul li .box span {
  display: inline-block;
  vertical-align: middle;
}

.property-dtl-tab {
  padding: 20px 0 0 0;
}

.property-dtl-tab .nav li {
  padding: 0 40px 0 0;
  margin: 0;
  width: inherit;
}

.property-dtl-tab .nav li a {
  font-size: 16px;
  color: #000;
  font-weight: normal;
  text-transform: uppercase;
  border-radius: 0;
  border: none;
  padding: 0 0 16px 0;
  background: none;
  position: relative;
  margin: 0;
  outline: none;
}

.property-dtl-tab .nav li a:before {
  height: 3px;
  position: absolute;
  left: 0;
  right: 0;
  bottom: -2px;
  content: '';
  background: #0000ff;
  display: none;
}

.property-dtl-tab .nav li.active a:before {
  display: block;
}

#tab-content-b li a:before {
  display: none !important;
}

.property-dtl-tab .nav-tabs > li.active > a,
.property-dtl-tab .nav-tabs > li > a:hover,
.property-dtl-tab .nav-tabs > li.active > a:hover,
.property-dtl-tab .nav-tabs > li.active > a:focus {
  color: #0000ff;
  font-weight: bold;
  margin: 0;
  outline: none;
  border: none;
}

.property-dtl-tab .tab-content {
  padding: 40px 0 0 0;
}

.property-dtl-tab .tab-content p {
  font-size: 14px;
  line-height: 30px;
  color: #c2c2c2;
  font-weight: normal;
  margin: 0;
  padding: 0 0 25px 0;
}

.property-dtl-tab .tab-content h2 {
  font-size: 20px;
  color: #000;
  font-weight: bold;
  margin: 0;
  padding: 0 0 20px 0;
}

.summary-box {
  padding: 15px 0 20px 0;
}

.summary-box h3 {
  font-size: 22px;
  color: #ffffff;
  font-weight: bold;
  margin: 0;
  padding: 0 0 20px 0;
}

.summary-box ul {
  padding: 0;
  margin: 0;
}

.summary-box ul li {
  padding: 0;
  margin: 0;
  list-style: none;
  float: left;
  width: 25%;
}

.summary-box ul li .box {
  padding: 0;
  margin: 0;
}

.summary-box ul li .box p {
  padding: 0;
  margin: 0;
  font-size: 16px;
  color: #ffffff;
  font-weight: normal;
}

.summary-box ul li .box p strong {
  font-size: 18px;
  color: #c2c2c2;
  font-weight: bold;
  display: block;
}

.neg-left {
  width: 370px;
  margin: 0 30px 0 0;
  float: left;
}

.neg-right {
  overflow: hidden;
}

body.modal-open,
body {
  padding-right: 0 !important;
  overflow-x: hidden;
  overflow-y: auto;
}

.modal {
  overflow-y: auto;
}

/*.coman-modal.in{ display: flex !important; align-items: center; }*/

.coman-modal .modal-dialog {
  width: 500px;
  margin: 60px auto;
  padding: 20px;
}

.coman-modal .modal-content {
  border-radius: 5px;
  padding: 25px;
  max-height: 82vh;
  overflow-y: scroll;
}

/*.coman-modal .modal-content:textarea.form-controltextarea.form-control{ position: absolute; left: -10px; top: -10px; height: 10px; width: 100%; content: ""; background: #0000ff; z-index: 1; }*/

/*.coman-modal .modal-content:after{ position: absolute; left: -10px; top: -10px; height: 100%; width: 10px; content: ""; background: #0000ff; z-index: 1; }*/

.coman-modal .modal-content h2 {
  text-align: center;
  font-size: 22px;
  color: #626262;
  font-weight: bold;
  margin: 0;
  padding: 15px 0;
}

.coman-modal .modal-content h2.p-t-0 {
  padding-top: 0;
}

.coman-modal .modal-content > img {
  width: 100px;
}

.coman-modal .form-group {
  margin-bottom: 15px;
}

.coman-modal .form-control {
  font-size: 14px;
  color: #000333;
  font-weight: normal;
  background-color: #f6f7f9;
  height: 40px;
  border: 1px solid #c3bcbc;
  border-radius: 3px;
  padding: 6px 12px 6px 50px;
  padding-left: 15px;
}

.coman-modal .form-control-a {
  font-size: 14px;
  color: #000333;
  font-weight: normal;
  background-color: #f6f7f9;
  height: 70px;
  border: 1px solid #c3bcbc;
  border-radius: 3px;
  padding: 6px 12px 6px 6px;
}

.coman-modal .form-control::placeholder {
  color: #8e8e8e;
  opacity: 1;
}

.forgot-link {
  text-align: right;
  padding: 0 0 15px 0;
}

.forgot-link a {
  font-size: 15px;
  color: #0c5f97;
  font-weight: normal;
  margin: 0;
  padding: 0;
  display: inline-block;
  vertical-align: top;
}

.forgot-link a:hover {
  text-decoration: underline;
}

.coman-modal .btn-primary {
  width: 100%;
  border-radius: 3px;
}

.coman-modal .btn-default {
  width: 100%;
  border-radius: 3px;
}

.dont-ac {
  text-align: center;
  padding: 20px 0 0 0;
}

.dont-ac p {
  font-size: 16px;
  color: #000333;
  font-weight: normal;
  margin: 0;
  padding: 0;
}

.dont-ac p br {
  display: none;
}

.dont-ac p a {
  text-decoration: underline;
  font-size: 16px;
  color: #0000ff;
}

.dont-ac p a:hover {
  text-decoration: none;
}

.signup-tab .nav {
  margin: 0;
  padding: 0;
}

.signup-tab .nav li {
  margin: 0;
  padding: 0;
  float: left;
  width: 50%;
  text-align: center;
}

.signup-tab .nav li a {
  font-size: 16px;
  color: #000333;
  font-weight: bold;
  margin: 0;
  padding: 0 0 10px 0;
  border: none;
  border-radius: 0;
  background: none;
  display: block;
}

.signup-tab .nav-tabs > li.active > a,
.signup-tab .nav-tabs > li.active > a:hover,
.signup-tab .nav-tabs > li > a:focus,
.signup-tab .nav-tabs > li.active > a:focus {
  background: none;
  border: none;
  outline: none;
  color: #0000ff;
  border-bottom: 2px solid #0000ff;
}

.signup-tab .tab-content {
  padding: 15px 0 0 0;
}

.agree-box {
  text-align: left;
  padding: 0 0 10px 0px;
}

.agree-box label {
  font-size: 16px;
  color: #000333;
  font-weight: normal;
  margin: 0;
  padding: 0;
}

.agree-box label a {
  color: #0000ff;
  text-decoration: underline;
}

.agree-box label a:hover {
  text-decoration: none;
}

#tab-content-a h2 {
  font-size: 17px;
  padding-bottom: 5px;
}

#tab-content-a p {
  padding-bottom: 0px;
  font-size: 33px;
  color: #41a2ca;
  line-height: 33px;
  font-weight: bold;
  margin-top: 5px;
}

.property-dtl-tab .nav-tabs .ibox-content {
  background-color: #1b2229;
  border-color: #1b2229;
  background: linear-gradient(
    90deg,
    rgb(71, 161, 202) 0%,
    rgb(95, 157, 202) 35%,
    rgb(54, 165, 202) 100%
  );
  padding: 20px;
  border-radius: 8px;
}

.dark-bg {
  /* background-color: #e0e5e6; */
  background-color: #f6f7fb;
}

.property-dtl-tab .nav-tabs > li.active > a > .ibox-content,
.property-dtl-tab .nav-tabs > li > a:hover > .ibox-content,
.property-dtl-tab .nav-tabs > li.active > a:hover > .ibox-content,
.property-dtl-tab .nav-tabs > li.active > a:focus > .ibox-content {
  background: linear-gradient(rgb(190, 251, 255) 0%, rgb(54, 165, 202) 57%);
}

.ibox-content {
  background-color: #fff;
  border-color: #fff;
  background: #fff;
  padding: 20px;
  border-radius: 4px;
}
.logisticarrival {
  display: flex !important;
  justify-content: space-between !important;
}
.dark-bg-header {
  background-color: #fff;
  border-color: #fff;
}

#tab-content-a .ibox-content {
  background-color: #1b2229;
  border-color: #1b2229;
  background: #1b2229;
}

.float-n {
  float: none;
}

#side-menu .nav-header {
  padding: 18px 10px;
  border-bottom: 1px solid #e0e5e6;
}

.navbar.navbar-static-top {
  height: 70px;
  box-shadow: 0px 2px 4px 0px #62626263;
  /* padding: 7px 3px 0 0; */
}

body.mini-navbar .nav-header {
  padding: 0;
  background: #626262;
}

.mini-navbar .nav-user-part h3 {
  font-size: 13px;
}

.mini-navbar .nav > li > a {
  /* padding: 14px 20px 14px 22px; */
  padding-left: 18px;
}

body.mini-navbar .profile-element,
body.mini-navbar .nav-label,
body.mini-navbar .navbar-default .nav li a span {
  display: none;
}

.nav-tabs {
  border-bottom: 0px;
}

.mini-navbar .nav li:hover > .nav li a span {
  display: block;
  position: absolute;
  top: 0px;
  left: 62px;
  background: #fff;
  padding: 8px;
  padding: 14px 20px 14px 22px;
}

.mini-navbar .nav-user-part h4 {
  font-size: 11px;
}

.mini-navbar .nav-user-part .image img {
  width: 100%;
}

/* .nav-user-part{ text-align: center; padding: 20px 10px 17px 10px; } */

.nav-user-part .image a {
  display: inline-block;
  padding: 0;
  outline: none;
}

.navbar-default .nav-user-part .image a:hover,
.navbar-default .nav-user-part .image a img:hover {
  background: #0a1119;
}

.nav-user-part .image img {
  width: 55%;
  height: auto;
  border-radius: 50%;
  object-fit: cover;
  object-position: center;
}

.nav-user-part h4 {
  font-size: 16px;
  color: #ffffff;
  font-weight: normal;
  margin: 0;
  padding: 12px 0 7px 0;
}

.nav-user-part h3 {
  font-size: 20px;
  color: #ffffff;
  font-weight: normal;
  margin: 0;
  padding: 0;
}

/* .nav.navbar-right li{ padding: 0 0 0 40px; }
.nav.navbar-right li a{ padding: 0; margin: 0; font-size: 16px; color: #ffffff; font-weight: normal; min-height: inherit; }
.nav.navbar-right li a:hover{ color: #0000ff; }
.nav.navbar-right li a.active{ color: #0000ff; } */

/*.metismenu li{ border-left: 4px solid #885b0f; }*/

.metismenu li a {
  font-size: 14px;
  color: #ffffff;
  font-weight: normal;
  margin: 0;
  padding: 14px 15px 15px 15px;
}

.metismenu li a span {
  display: inline-block;
  vertical-align: middle;
  padding: 0 0 0 7px;
}

.metismenu li a .icon {
  width: 26px;
  height: 21px;
  display: inline-block;
  vertical-align: middle;
}

.metismenu li.active {
  border-left: 4px solid #0000ff;
  background: #0b1a20;
}

.metismenu li.active a {
  color: #fff;
  margin-left: -4px;
}

.metismenu li.active a .icon {
  background-position: 0 bottom;
}

.navbar-default .metismenu li a:hover,
.navbar-default .metismenu li a:focus {
  background: transparent;
  color: #0000ff;
}

.navbar-default .metismenu li:hover,
.navbar-default .metismenu li:focus {
  border-color: transparent;
}

.navbar-default .metismenu li:nth-child(1):hover {
  border-left: none;
}

.navbar-default .metismenu li a:hover .icon,
.navbar-default .metismenu li a:focus .icon {
  background-position: 0 bottom;
}

.navbar-default .nav-header .logo-panel a {
  display: block;
  padding: 0;
}

.navbar-default .nav-header .logo-panel a:hover {
  background: none;
}

.box-heading h2 {
  font-size: 18px;
  font-weight: bold;
  color: #000;
  font-weight: bold;
  margin: 0;
  padding: 30px 0 15px 0;
}

.box-heading h5 {
  font-size: 16px;
  color: #000;
  font-weight: normal;
  margin: 0;
  padding: 0 0 20px 0;
}

.ibox-content img {
  width: 100%;
}
 .empImg {
    width: 25% !important;
    height: 25% !important;
  }
.chk {
  position: relative;
  float: left;
  margin: 0 15px 0 0;
}

.chk input {
  width: 24px;
  height: 24px;
  opacity: 0;
  display: inline-block;
  vertical-align: top;
  margin: 0;
  padding: 0;
  position: absolute;
  left: 0;
  top: 0;
  z-index: 2;
  cursor: pointer;
}

.chk span {
  display: inline-block;
  width: 20px;
  height: 20px;
  border: 2px solid #22c0a1;
  vertical-align: top;
  background: #0c151e;
}

.bank-details-form {
  max-width: 970px;
  margin: 0 auto;
  padding: 20px 0;
}

.form-control {
  font-size: 12px;
  color: #000;
  font-weight: normal;
  margin: 0;
  padding: 6px 10px;
  height: 39px;
  background: #fff;
  border: 1px solid #c3bcbc;
  border-radius: 3px;
}
.vehicleOwner {
  font-size: 12px;
  color: #000;
  font-weight: normal;
  margin: 0;
  padding: 6px 10px;
  height: 39px !important;
  background: #fff;
  border: 1px solid #c3bcbc;
  border-radius: 3px;
}
.promoter {
  font-size: 12px;
  color: #000;
  font-weight: normal;
  margin: 0;
  padding: 6px 10px;
  height: 39px !important;
  background: #fff;
  border: 1px solid #c3bcbc;
  border-radius: 3px;
}

.form-group {
  margin-bottom: 0px !important;
}

.coman-table table tr th {
  font-size: 14px;
  color: #ffffff;
  font-weight: normal;
  margin: 0;
  padding: 10px 0;
  border: none;
}

.coman-table table tr td {
  font-size: 12px;
  color: #ffffff;
  font-weight: normal;
  margin: 0;
  padding: 15px 0;
  border-top: 1px solid #252d35;
}

select.form-control {
  font-size: 12px;
  color: #000;
  font-weight: normal;
  background: url(../img/select-box-arrow.png) right center no-repeat #fff;
  border: 1px solid #906418;
  border-radius: 3px;
  height: 36px;
  -moz-appearance: none;
  appearance: none;
  -webkit-appearance: none;
  -ms-appearance: none;
  -o-appearance: none;
}
.promoter {
  font-size: 12px;
  color: #000;
  font-weight: normal;
  background: url(../img/select-box-arrow.png) right center no-repeat #fff;
  border: 1px solid #906418;
  border-radius: 3px;
  height: 39px !important;
  -moz-appearance: none;
  appearance: none;
  -webkit-appearance: none;
  -ms-appearance: none;
  -o-appearance: none;
}

.cal-fr img {
  width: 38px;
}

.guide-box {
  padding: 10px 0 0 0;
}

.guide-box h3 {
  font-size: 20px;
  color: #0000ff;
  font-weight: bold;
  margin: 0;
  padding: 0 0 15px 0;
}

.guide-box p {
  font-size: 17px;
  line-height: 24px;
  color: #ffffff;
  font-weight: normal;
  margin: 0;
  padding: 0 0 20px 0;
}

.guide-box ul {
  margin: 0 0 0 -30px;
  padding: 0;
}

.guide-box ul li {
  margin: 0;
  list-style: none;
  float: left;
  width: 50%;
  padding: 0 0 30px 30px;
}

.guide-box ul li .box {
  text-align: center;
  background: rgba(255, 255, 255, 0.4);
  padding: 10px;
}

.agreement-list {
  padding: 20px 0 10px 0;
}

.agreement-list ul {
  padding: 0;
  margin: 0;
}

.agreement-list ul li {
  padding: 0;
  margin: 0;
  list-style: none;
}

.agreement-list ul li .box {
  margin: 0 0 30px 0;
}

.cal-input {
  padding-left: 10px !important;
}

.glyphicon {
  color: #0000ff;
}

.navbar-minimalize {
  padding: 5px 13px;
}

.input-group-addon {
  background-color: #fff;
  /*border-color: #c3bcbc;*/
  border-radius: 4px;
  color: inherit;
  font-size: 14px;
  font-weight: 400;
  line-height: 1;
  padding: 6px 12px;
  text-align: center;
}

.ibox-title p {
  margin-top: 10px;
}

.chk {
  position: relative;
  float: left;
  margin: 0 15px 0 0;
}

.chk input {
  width: 24px;
  height: 24px;
  opacity: 0;
  display: inline-block;
  vertical-align: top;
  margin: 0;
  padding: 0;
  position: absolute;
  left: 0;
  top: 0;
  z-index: 2;
  cursor: pointer;
}

.chk span {
  display: inline-block;
  width: 20px;
  height: 20px;
  border: 2px solid #0000ff;
  vertical-align: top;
  background: #808186;
}

.agreement-list ul li .box p {
  font-size: 17px;
  color: #ffffff;
  font-weight: normal;
  margin: 0;
  padding: 0;
  display: table-cell;
}

.agreement-list ul li .box p a {
  color: #0000ff;
}

.agreement-list ul li .box p a:hover {
  text-decoration: underline;
}

.kyc-status {
  padding: 80px;
}

.kyc-status img {
  width: auto;
}

.kyc-status h3 {
  font-size: 40px;
  color: #0000ff;
  font-weight: bold;
  margin: 0;
  padding: 30px 0 0 0;
}

.property-top-section .image {
  width: 230px;
  float: left;
  margin: 0 30px 0 0;
}

.property-top-section .image img {
  width: 100%;
}

.property-top-section .text {
  overflow: hidden;
}

.property-top-section .text h2 {
  font-size: 22px;
  color: #ffffff;
  font-weight: normal;
  margin: 0;
  padding: 0 0 10px 0;
}

.property-top-section .text h2 span {
  display: inline-block;
  border-left: 1px solid #0000ff;
  padding-left: 13px;
  margin-left: 10px;
}

.property-top-section .text p {
  font-size: 16px;
  color: #ffffff;
  font-weight: normal;
  margin: 0;
  padding: 0;
}

.property-top-section .text p img {
  width: auto;
}

.cap-list {
  padding: 20px 0 0 0;
}

.cap-list ul {
  margin: 0;
  padding: 0;
}

.cap-list ul li {
  margin: 0;
  padding: 5px 0;
  list-style: none;
  width: 50%;
  float: left;
}

.cap-list ul li p {
  font-size: 16px;
  font-weight: normal;
  margin: 0;
  padding: 0;
  color: #ffffff;
}

.cap-list ul li p strong {
  font-size: 18px;
}

.back-btn {
  padding: 0 0 20px 0;
}

.back-btn a {
  display: inline-block;
}

.back-btn a img {
  width: auto;
}

.Assets-dtl-tab {
  padding: 0;
}

.Assets-dtl-tab .tab-content {
  padding: 30px 0 0 0;
}

.Assets-dtl-tab .nav-tabs {
  border-color: #182129;
}

.Asset-summary-list h3 {
  font-size: 22px;
  color: #ffffff;
  font-weight: normal;
  margin: 0;
  padding: 0 0 20px 0;
}

.Asset-summary-list ul {
  padding: 0;
  margin: 0;
}

.Asset-summary-list ul li {
  padding: 0 10px 10px 0;
  margin: 0;
  list-style: none;
  float: left;
  width: 25%;
}

.Asset-summary-list ul li h5 {
  font-size: 18px;
  color: #ffffff;
  font-weight: bold;
  margin: 0;
  padding: 0;
}

.Asset-summary-list ul li h5 span {
  font-size: 16px;
  display: block;
  font-weight: normal;
  padding: 0 0 12px 0;
}

.Assets-statistics .box {
  padding: 20px;
  margin-bottom: 30px;
  border: 1px solid #182129;
}

.wallet-list ul {
  margin: 0;
  padding: 0;
}

.wallet-list ul li {
  margin: 0;
  padding: 0;
  list-style: none;
}

.wallet-list ul li .box {
  padding: 20px;
  background: #11303a;
  margin-top: 20px;
}

.wallet-list ul li .box h4 {
  font-size: 18px;
  color: #ffffff;
  font-weight: normal;
  margin: 0;
  padding: 0 0 10px 0;
}

.wallet-list ul li .box h3 {
  font-size: 25px;
  color: #0000ff;
  font-weight: normal;
  margin: 0;
  padding: 0;
}

.my-Assets-tab .tab-content {
  padding: 30px 0 0 0;
}

.ast-list {
  display: block;
}

.ast-list .box {
  margin-bottom: 30px;
}

.ast-list .box img {
  width: auto;
}

.ast-list .box .image img {
  width: 100%;
}

.ast-list .box .text {
  padding: 19px 19px 15px 19px;
  border: 1px solid #dedede;
  border-top: 0;
  background: #ffffff;
}

.ast-list .box .text h3 {
  font-size: 18px;
  color: #000000;
  font-weight: bold;
  margin: 0;
  padding: 0 0 8px 0;
}

.ast-list .box .text h3 a {
  color: #000000;
}

.ast-list .box .text h3 a:hover {
  color: #0000ff;
}

.ast-list .box .text p {
  font-size: 14px;
  line-height: 22px;
  color: #888888;
  font-weight: normal;
  margin: 0;
  padding: 0;
}

.ast-list .box .text h5 {
  font-size: 14px;
  color: #000000;
  font-weight: normal;
  margin: 0;
  padding: 12px 0 0 0;
}

.ast-list .box .text h6 {
  font-size: 14px;
  color: #888888;
  font-weight: normal;
  margin: 0;
  padding: 5px 0;
  text-align: right;
  clear: both;
  overflow: hidden;
}

.ast-list .box .text h6 span {
  font-size: 16px;
  color: #000000;
  display: inline-block;
  float: left;
  padding-right: 10px;
}

.as-info {
  border-top: 1px solid #dedede;
  margin: 15px 0 0 0;
  padding: 12px 0 0 0;
}

.modal-Asset-status .modal-dialog {
  width: auto;
  margin: 0px 50px 0px 67px;
  top: 50%;
  margin-top: -140px;
}

.modal-Asset-status .modal-header {
  padding: 0;
  border: none;
}

.modal-Asset-status .close {
  background: #0000ff;
  color: #ffffff;
  font-weight: normal;
  width: 24px;
  height: 24px;
  line-height: 24px;
  border-radius: 50%;
  opacity: 1;
  font-size: 18px;
  position: absolute;
  right: -10px;
  top: -16px;
}

.modal-Asset-status .close span {
  vertical-align: top;
  display: inline-block;
  margin-top: -1px;
}

.modal-Asset-status .modal-body {
  padding: 0;
}

.modal-Asset-status .modal-body h2 {
  font-size: 26px;
  color: #000000;
  font-weight: bold;
  margin: 0;
  padding: 0 0 10px 0;
}

.modal-Asset-status .modal-body p {
  font-size: 16px;
  color: #888888;
  font-weight: normal;
  margin: 0;
  padding: 0 0 10px 0;
}

.ast-status-bar {
  padding: 20px 0 0px 0;
}

.ast-status-bar ul li {
  width: 11.11%;
}

.ast-status-bar ul li .box {
  position: relative;
}

.ast-status-bar ul li .box:before {
  position: absolute;
  top: 28px;
  height: 5px;
  width: calc(100% - 70px);
  margin-top: -4px;
  right: 50%;
  content: '';
  background: #a7abae;
  z-index: 1;
  margin-right: 35px;
  border-radius: 5px;
}

.ast-status-bar ul li.current .box:before {
  background: #0000ff;
}

.ast-status-bar ul li:first-child .box:before {
  display: none;
}

.ast-status-bar ul li .box .icon {
  width: 50px;
  height: 50px;
  border-radius: 50%;
  margin: 0 auto;
  position: relative;
  z-index: 2;
}

.ast-status-bar ul li .box h4 {
  font-size: 14px;
  color: #000000;
  font-weight: normal;
  margin: 0;
  padding: 15px 10px 0 10px;
}

.reset-password-form {
  max-width: 500px;
  margin: 0 auto;
  padding: 30px 0;
}

#modal-new-Asset .modal-body {
  padding: 0;
}

#modal-new-Asset .modal-body h2 {
  font-size: 22px;
  padding: 0 0 30px 0;
}

.docs-list h3,
.ast-images-part h3 {
  font-size: 18px;
  color: #ffffff;
  font-weight: normal;
  margin: 0;
  padding: 0 0 20px 0;
}

.docs-list {
  padding: 10px 0 0 0;
}

.docs-list ul {
  margin: 0 0 0 -30px;
  padding: 10px 0 0 0;
}

.docs-list ul li {
  margin: 0;
  padding: 0 0 30px 30px;
  list-style: none;
  float: left;
  width: 16.6666%;
}

.docs-list ul li .box {
  position: relative;
}

.docs-list ul li .box .cls-btn {
  position: absolute;
  right: -12px;
  top: -12px;
  z-index: 2;
}

.docs-list + .docs-list {
  border-bottom: 1px solid #252d35;
}

.bdr-none {
  border-bottom: none !important;
}

.ast-images-part {
  padding: 30px 0 0 0;
}

.rnt-same-form {
  padding: 20px 0 0 0;
}

.rnt-same-form label {
  font-size: 20px;
  line-height: 21px;
  color: #a5a5a5;
  font-weight: normal;
  margin: 0 5px 0 0;
  vertical-align: top;
}

.rnt-same-form label + label {
  margin-left: 25px;
}

.amenities-list ul {
  margin: 0;
  padding: 0;
}

.amenities-list ul li {
  margin: 0;
  padding: 0;
  list-style: none;
  float: left;
  width: 25%;
}

.amenities-list ul li .box {
  margin: 0 0 20px 0;
}

.amenities-list ul li .box .checkbox label {
  font-size: 18px;
  color: #ffffff;
  font-weight: normal;
}

.amenities-list ul li .box h4 {
  font-size: 14px;
  color: #ffffff;
  font-weight: normal;
  margin: 0;
  padding: 4px 0 0 0;
}

.all-ch-box h2 {
  float: left;
  margin-right: 25px;
}

.all-ch-box .chk {
  margin: 3px 15px 0 0;
}

.all-ch-box h4 {
  font-size: 18px;
  color: #ffffff;
  font-weight: normal;
}

hr {
  border-color: #252d35;
}

.ast-list-status .box {
  position: relative;
}

.hov-link {
  display: none;
}

.ast-list-status .box:hover .hov-link {
  display: block;
}

.cms-pages-section p {
  font-size: 16px;
  line-height: 26px;
  color: #ffffff;
  font-weight: normal;
  margin: 0;
  padding: 0 0 20px 0;
}

.cms-pages-section p a {
  color: #ffffff;
  text-decoration: underline;
}

.cms-pages-section p a:hover {
  text-decoration: none;
}

.cms-pages-section h3 {
  font-size: 22px;
  line-height: 32px;
  color: #ffffff;
  font-weight: normal;
  margin: 0;
  padding: 10px 0 7px 0;
}

.notification-list ul {
  padding: 0;
  margin: 0;
}

.notification-list ul li {
  padding: 0;
  margin: 0;
  list-style: none;
  border-bottom: 1px solid #e0e5e6;
}

.notification-list ul li .box {
  padding: 10px 0;
}

.notification-list ul li .box .image {
  width: 30px;
  float: left;
  margin: 0 20px 0 0;
}

.notification-list ul li .box .text {
  overflow: hidden;
  padding: 7px 0 0 0;
}

.notification-list ul li .box .text p {
  font-size: 14px;
  color: #000000;
  font-weight: normal;
  margin: 0;
  padding: 0;
  text-align: left;
}

.notification-list ul li .box .text p a {
  color: #0000ff;
  font-weight: bold;
  padding-left: 15px;
}

.notification-list ul li .box .text p span {
  display: inline-block;
  float: right;
}

.faq-list ul {
  padding: 0;
  margin: 0;
}

.faq-list ul li {
  padding: 0px 0;
  margin: 0;
  list-style: none;
}

.faq-list ul li .box {
  padding: 15px 0;
}

.faq-list ul li .box h3 {
  font-size: 20px;
  color: #0000ff;
  font-weight: normal;
  margin: 0;
  padding: 0 0 10px 0;
}

.faq-list ul li .box p {
  font-size: 18px;
  line-height: 30px;
  color: #ffffff;
  font-weight: normal;
  margin: 0;
}

.faq-list ul li .box p span {
  font-size: 24px;
}

.faq-list ul li .box a {
  color: #0000ff;
}

.faq-list ul li .box a:hover {
  color: #0000ff;
  text-decoration: underline;
}

.contact-map {
  margin-bottom: 30px;
}

.contact-map iframe {
  width: 100%;
  height: 780px;
}

.title-def h5 {
  font-size: 16px;
  color: #ffffff;
  font-weight: normal;
  margin: 0;
  padding: 0 0 6px 0;
}

.cnt-info-a {
  margin: 0 0 20px 0;
}

.cnt-info-a img {
  float: left;
}

.cnt-info-a p {
  font-size: 18px;
  line-height: 26px;
  color: #ffffff;
  font-weight: normal;
  margin: 0;
  padding: 0 0 0 13px;
  float: none;
  overflow: hidden;
}

.cnt-info-a p a {
  color: #ffffff;
}

.cnt-info-a p a:hover {
  color: #0000ff;
}

.cnt-info-c img {
  margin-top: 4px;
}

.cnt-form .form-control {
  background-color: #ffffff;
}

.my-ac-section {
  max-width: 460px;
  margin: 0 auto;
  text-align: center;
  padding: 20px 0;
}

.my-ac-img {
  margin-bottom: 40px;
  position: relative;
  display: inline-block;
}

.my-ac-img img {
  width: 220px;
  height: 220px;
  border-radius: 50%;
  object-fit: cover;
  object-position: center;
  border: 8px solid #0000ff;
  max-width: 220px;
}

.my-ac-section .form-control {
  padding: 6px 12px 6px 50px;
}

.change-pw {
  text-align: right;
  padding: 0 0 20px 0;
}

.change-pw a {
  font-size: 18px;
  color: #94681c;
  font-weight: normal;
  margin: 0;
  padding: 0;
  display: inline-block;
}

.change-pw a img {
  width: auto;
  margin: -2px 3px 0 0;
}

.change-pw a:hover {
  color: #0000ff;
}

.bank-details-view label {
  font-size: 18px;
  color: #ffffff;
  font-weight: normal;
  margin: 0;
  padding: 0;
}

.bank-details-view p {
  font-size: 20px;
  color: #ffffff;
  font-weight: bold;
  margin: 0;
  padding: 0;
}

.edit-wallet {
  max-width: 460px;
  margin: 0 auto;
  padding: 20px 0;
}

.edit-wallet .form-group label {
  font-size: 18px;
  color: #ffffff;
  margin: 0;
  padding: 0 0 10px 0;
  display: inline-block;
  font-weight: normal;
}

.cnt-line {
  text-align: center;
  padding: 0 0 40px 0;
  margin: -20px 0 0 0;
}

.cnt-line p {
  font-size: 16px;
  color: #ffffff;
  font-weight: normal;
  margin: 0;
  padding: 0 0 0 0;
}

#forgot-pw-modal-link p {
  font-size: 16px;
  font-weight: normal;
  margin: 0;
  padding: 0;
}

#add-wallet-modal .form-control,
#send-modal .form-control,
#Edit-modal .form-control,
#View-cat .form-control,
#view-modal .form-control {
  padding-left: 20px;
}

.browse-btn {
  position: absolute;
  right: 15px;
  top: 20px;
}

.browse-btn span {
  width: 38px;
  height: 38px;
  line-height: 38px;
  display: inline-block;
  vertical-align: top;
  border-radius: 50%;
  background: #0000ff;
  padding: 1px 0 0 0;
}

.browse-btn span i {
  color: #ffffff;
  font-size: 16px;
}

.browse-btn input {
  position: absolute;
  left: 0;
  top: 0;
  opacity: 0;
  cursor: pointer;
  z-index: 2;
  width: 38px;
  height: 38px;
  border-radius: 50%;
}

/*my style*/

#sign-in h1 {
  margin-top: 20px;
  margin-bottom: 30px;
  color: #747679;
  font-weight: bold;
  text-transform: uppercase;
  font-size: 19px;
}

.coman-modal .modal-dialog.modal-a {
  max-width: 550px;
  width: 100%;
}

.modal-open .modal {
  overflow-y: auto;
}

.close:focus,
.close:hover {
  outline: none;
}

.success-msg-icon i {
  color: #36ca9a;
  font-size: 80px;
  margin-top: 10px;
  margin-bottom: 20px;
}

.modal h1,
.modal h2 {
  font-weight: bold;
  color: #000;
}

.modal p {
  font-size: 16px;
  color: #000;
}

#signup-tab1 input {
  padding-left: 45px;
}

#signup-tab1 select {
  padding-left: 45px;
}

.modal-width-600 {
  width: 100% !important;
  max-width: 800px;
}

/*verification */

#form input {
  margin: 0 5px;
  text-align: center;
  font-size: 20px;
  border: solid 1px #ccc;
  box-shadow: 0 0 5px #ccc inset;
  outline: none;
  transition: all 0.2s ease-in-out;
  border-radius: 3px;
}

#form input:focus {
  border-color: #ff9ef9;
  box-shadow: 0 0 5px #ff9ef9 inset;
}

#form input::-moz-selection {
  background: transparent;
}

#form input::selection {
  background: transparent;
}

.logo-panel img {
  /* width: 49px; */
  height: 33px;
}

.navbar-default {
  background: #1b2229;
}

#history .box {
  padding: 5px;
  background: #fff;
  margin-bottom: 0px;
  border-radius: 5px;
  color: #fff;
  text-align: center;
}

#history h3 {
  font-size: 23px;
}

#history h4 {
  font-size: 13px;
  color: #333333;
  font-weight: normal !important;
  margin-bottom: 5px;
}

#sent-modal .form-control {
  padding: 6px 12px 6px 12px;
}

.m-b-0 {
  margin-bottom: 0px !important;
}

.close {
  opacity: 1 !important;
}

.form-group:not(.google-map-react) img,
.form-group i:not(.fa-info-circle) {
  position: absolute;
  left: 17px;
  top: 9px;
  color: #404e67;
  width: 20px;
  font-size: 20px;
}
.profileChangePassword {
  position: absolute !important;
  left: 362px !important;
  top: 9px !important;
  color: #404e67 !important;
  width: 20px !important;
  font-size: 20px !important;
}
.vendorProfileChangePassword {
  position: absolute !important;
  left: 362px !important;
  top: 9px !important;
  color: #404e67 !important;
  width: 20px !important;
  font-size: 20px !important;
}
.form-group i.fa-info-circle {
  color: #404e67;
  margin-left: 5px;
}

.navbar-default .nav > li > a:hover,
.navbar-default .nav > li > a:focus {
  background: #6178a1;
  color: white;
}

.form-control:focus,
.single-line:focus {
  border-color: #0071ba !important;
}

.navbar-default {
  /* background-color: #626262; */
  background-color: #404e67;
  border-color: #626262;
  /* background-image: linear-gradient(to right, #0000ff 0%, #ffffff 100%) !important; */
}

.nav > li.active {
  border-left: 4px solid #fff;
  /* background: #0000ff; */
}

.eml-form img {
  left: 15px;
}

.pw-form img {
  width: 15px;
  left: 18px;
  top: 11px;
}

#cal .input-group-addon {
  background-color: #252d35;
  border: 1px solid #60676d;
  border-right: 0px;
}

.nav.nav-tabs li:last-child {
  padding-right: 0px !important;
}

.usr-form img {
  top: 11px;
  width: 18px;
}

.ph-form img {
  top: 12px;
}

.pass-word img {
  left: 20px;
  width: 16px;
  top: 9px;
}

.datepicker table tr td.active,
.datepicker table tr td.active.disabled,
.datepicker table tr td.active.disabled:hover,
.datepicker table tr td.active:hover {
  background-image: linear-gradient(to bottom, #4b808a, #0000ff) !important;
}

.nav > li > a > img {
  max-width: none;
  width: 25px;
  margin-left: 5px;
  margin-right: 6px;
}

#admin-dashboard .ibox-content {
  background-color: #579eca;
}

#admin-dashboard h2 {
  font-size: 30px;
  color: #fff;
  font-weight: bold;
}

#ad-dashboard h1 {
  font-size: 20px;
  color: #fff;
}

#Status-modal label {
  font-size: 16px;
}

#Status-modal .modal-dialog.modal-a {
  max-width: 330px;
}

.max-83 {
  max-width: 83px;
}

.max-150 {
  max-width: 150px;
}

.btn-primary.btn-small.max-83 {
  padding: 8px 12px;
}

.pace .pace-progress {
  background: #107188;
  position: fixed;
  z-index: 2040;
  top: 0;
  right: 100%;
  width: 100%;
  height: 2px;
}

body.mini-navbar.fixed-sidebar .profile-element,
.block {
  display: block !important;
  margin-left: 15px;
}

/* .text-muted {
    color: #0000ff;
    margin-top: 10px;
} */

.box-wallet {
  padding: 10px;
  background-color: #626262;
  /*  border-radius: 8px;*/
  color: #fff;
  margin-bottom: 10px;
}

.box-wallet h4 {
  font-size: 18px;
}

.box-wallet h3 {
  font-size: 24px;
}

.form-group label {
  font-size: 14px;
}

/*
To change this license header, choose License Headers in Project Properties.
To change this template file, choose Tools | Templates
and open the template in the editor.
*/

/* 
    Created on : 2 Jul, 2018, 3:15:44 PM
    Author     : Hp
*/

input[type='number']::-webkit-outer-spin-button,
input[type='number']::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.rate-style {
  font-weight: 700;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border: 1px solid;
  border-color: #885a14;
  border-radius: 6px;
  margin-top: 8px;
}

.input-type {
  /* height: 44px; */
  border: 1px solid #936619;
  background-color: #f3f0e0;
  border-radius: 4px;
  font-size: 16px;
  /* width: 80px; */
  text-align: center;
  color: #936619;
  /* padding-top: 14px; */
  margin-right: 10px;
  padding: 10px;
  white-space: nowrap;
}

.kyc-top-bar ul li .box.current:before {
  background: #39af37;
}

.current .box i {
  color: #fff !important;
}

.kyc-top-bar ul li .current.box .ic-2 {
  background: #39af37;
}

.rate-space {
  padding: 10px;
  /* overflow: hidden; */
}

.rate {
  font-size: 170%;
  padding-bottom: 20px;
  color: #0000ff;
}

.dashboard-box-css {
  border-radius: 6px;
  box-shadow: 4px 0px 5px 0px #cccccc;
  height: 60px;
  width: 150px;
  background-image: linear-gradient(
    to right,
    #0000ff 0%,
    #c19d46 100%
  ) !important;
  /* margin-top: 10px; */
}

.kyc-top-bar {
  text-align: center;
  padding: 20px 0;
}

.kyc-top-bar ul {
  margin: 0;
  display: flex;
  justify-content: center;
  padding: 0;
}

.kyc-top-bar ul li {
  margin: 0 0px;
  padding: 0;
  list-style: none;
  width: 12.5%;
  float: left;
}

.kyc-top-bar ul li .box {
  position: relative;
}

.kyc-top-bar ul li .box:before {
  position: absolute;
  top: 50%;
  height: 8px;
  width: calc(100%);
  margin-top: -4px;
  right: 7%;
  content: '';
  background: #9e9696;
  z-index: 1;
  margin-right: 65px;
  border-radius: 5px;
}

.kyc-top-bar ul li.current .box:before {
  background: #0000ff;
}

.kyc-top-bar ul li:first-child .box:before {
  display: none;
}

.kyc-top-bar ul li .box .icon {
  width: 70px;
  height: 70px;
  border-radius: 50%;
  margin: 0 auto;
  position: relative;
  z-index: 2;
}

.kyc-top-bar ul li.current .box .icon {
  background-position: 0 bottom;
  /* background-color: #22c0a1; */
  background-color: #0000ff;
  border-color: #0000ff;
  /* background-image: linear-gradient(to bottom, #0000ff 0%, #c19d46 100%) !important; */
}

.kyc-section {
  max-width: 970px;
  margin: 0 auto;
  /* padding: 20px 0 0 0; */
}

.kyc-status {
  padding: 39px;
}

.kyc-status img {
  width: 180px;
}

.kyc-status h3 {
  font-size: 25px;
  color: #9a7229;
  font-weight: bold;
  margin: 0;
  padding: 30px 0 0 0;
}

.btn-kyc {
  max-width: 200px;
  margin: 0 auto;
  line-height: 28px;
}

.kyc-section h2 {
  font-size: 27px;
  color: #9a7229;
  font-weight: bold;
  margin: 0;
  padding: 0 0 20px 0;
  text-align: center;
}

.kyc-section h4 {
  font-size: 20px;
  color: #333333;
  font-weight: normal;
  margin: 0;
  padding: 0 0 20px 0;
  text-align: center;
}

#kyc-details .form-control {
  height: 40px;
  font-size: 14px;
  padding-left: 15px;
}

#kyc-details .form-group {
  margin-bottom: 15px;
}

#kyc-details .kyc-space {
  margin-bottom: 15px;
  padding-top: 10px;
}

.chk {
  position: relative;
  float: left;
  margin: 0 15px 0 0;
}

.chk input {
  width: 24px;
  height: 24px;
  opacity: 0;
  display: inline-block;
  vertical-align: top;
  margin: 0;
  padding: 0;
  position: absolute;
  left: 0;
  top: 0;
  z-index: 2;
  cursor: pointer;
}

.chk span {
  display: inline-block;
  width: 24px;
  height: 24px;
  border: 2px solid #2f1991;
  vertical-align: top;
  background: #fff;
}

.agreement-list {
  padding: 20px 0 10px 0;
}

.agreement-list ul {
  padding: 0;
  margin: 0;
}

.agreement-list ul li {
  padding: 0;
  margin: 0;
  list-style: none;
}

.agreement-list ul li .box {
  margin: 0 0 30px 0;
}

.agreement-list ul li .box p {
  font-size: 17px;
  color: #333;
  font-weight: normal;
  margin: 0;
  padding: 0;
}

.agreement-list ul li .box p a {
  color: #875914;
  line-height: 27px;
}

.agreement-list ul li .box p a:hover {
  text-decoration: underline;
}

select.form-control {
  font-size: 12px;
  color: #666;
  font-weight: normal;
  background: url(../img/select-box-arrow.png) right center no-repeat #ffffff;
  border: 1px solid #c3bcbc;
  border-radius: 3px;
  height: 36px;
  -moz-appearance: none;
  appearance: none;
  -webkit-appearance: none;
  -ms-appearance: none;
  -o-appearance: none;
  margin-bottom: 5px;
}

select.form-control1 {
  font-weight: normal;
  background: url(../img/select-box-arrow.png) right center no-repeat #c3bcbc;
  -moz-appearance: none;
  appearance: none;
  -webkit-appearance: none;
  -ms-appearance: none;
  -o-appearance: none;
}

.guide-box {
  padding: 10px 0 0 0;
}

.guide-box h3 {
  font-size: 20px;
  color: #93671b;
  font-weight: normal;
  margin: 0;
  padding: 0 0 15px 0;
}

.guide-box p {
  font-size: 17px;
  line-height: 24px;
  color: #666666;
  font-weight: normal;
  margin: 0;
  padding: 0 0 20px 0;
}

.guide-box ul {
  margin: 0 0 0 -30px;
  padding: 0;
}

.guide-box ul li {
  margin: 0;
  list-style: none;
  float: left;
  width: 50%;
  padding: 0 0 30px 30px;
}

.guide-box ul li .box {
  text-align: center;
  background: rgb(190, 152, 66);
  padding: 10px;
}

.fa-file-pdf-o:before {
  color: #0b599c;
  font-size: 20px;
}

.error,
.errors-wallet.error,
.buyError.error {
  font-size: 14px;
  font-weight: 600;
  color: red;
  line-height: 2;
  text-transform: capitalize;
  /*display: none;*/
}

textarea.form-control {
  font-size: 20px;
  word-spacing: 18px;
  padding-left: 10px;
  word-spacing: normal;
}

.verifyMnemonic .success,
.errors-wallet.success,
.buyError.success {
  font-size: 16px;
  color: #0f0;
  margin: 10px;
  /*display: none;*/
}

td {
  /* max-width: 130px; */
  word-wrap: break-word;
}

input#refrral_code {
  text-align: center;
  font-size: 20px;
  font-weight: 800;
  border: 0;
  color: #333333;
  width: 100%;
  pointer-events: none;
}

input#refrral_code:focus {
  border: 0;
  outline: 0;
  box-shadow: 0;
}

h1.qr_text {
  color: black;
  font-size: 40px;
  font-weight: 800;
  padding: 20px;
}

.qr_img {
  max-width: 300px;
  width: 100%;
}

.myaddress {
  text-align: center;
  margin: 0 auto;
  max-width: 100% !important;
  padding: 53px 0 105px 0;
}

.cr-code-box {
  clear: both;
  overflow: hidden;
}

.cr-code-box select {
  width: 80px;
  float: left;
  padding: 0 14px !important;
}

.cr-code-box input {
  width: 285px;
  float: right;
}

h6.warning_msg {
  color: red;
  font-weight: 500;
  font-size: 12px;
  text-align: center;
  margin-top: 10px;
}

img.img-circle {
  height: 40px;
  width: 40px;
}

.wallet-sl-box ul li.active a {
  color: #8b5e10 !important;
}

.jq-toast-wrap.bottom-left {
  word-wrap: break-word;
}

.app-bonuses-section {
  max-width: 100%;
}

#profile .form-group:not(.google-map-react) img {
  position: absolute;
  top: 12px;
  left: 16px;
  width: 17px;
}

#profile .form-control {
  height: 40px;
  font-size: 14px;
}

.dashboard-box-css {
  display: flex;
  align-items: center;
  justify-content: center;
}

.fa-usd,
.fa-btc {
  font-size: 25px;
}

.margin-bottom-20 {
  margin-bottom: 20px;
}

.kyc-color {
  color: #000;
}

.etherimg {
  width: 12px;
  /* text-align: justify; */
  margin-left: 8px;
  margin-top: 4px;
}

body::-webkit-scrollbar {
  width: 15px;
}

body::-webkit-scrollbar-track {
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
}

body::-webkit-scrollbar-thumb {
  background-color: #6178a1;
  outline: 1px solid slategrey;
}

.dashboard-box-css p {
  margin-bottom: 0px;
}

.dashboard-row {
  display: flex;
  align-items: flex-start;
}

.dashboard-row h2 {
  font-weight: bold;
  font-size: 20px;
  color: #fff;
}

.dashboard-row span {
  color: #fff;
  font-size: 30px;
}

label {
  margin-bottom: 0px;
}

.pad-box {
  background-image: linear-gradient(
    to right,
    #0000ff 0%,
    #c19d46 100%
  ) !important;
  padding: 20px;
  border-radius: 8px;
  margin-top: 8px;
}

#dashboard-head h2 {
  font-size: 35px;
  font-weight: bold;
  color: #0000ff;
}

#dashboard-head p {
  font-size: 20px;
  color: #0000ff;
  font-weight: bold;
  margin-bottom: 0px;
}

.prof-img {
  border: 4px solid #404e67;
  border-radius: 50%;
  /* height: 50px; */
  /* width: 50px; */
}

#modal-delete button,
#view-modal button,
#Edit-modal button {
  font-size: 16px;
  padding: 7px 0px;
  border-radius: 5px;
  max-width: 100px;
}

.margin-b-15 {
  margin-bottom: 10px;
}

#history-a h4 {
  font-size: 16px;
  color: #ffffff;
  font-weight: bold;
  /* background: #000; */
  background: linear-gradient(to right, #0b599c 0%, #1ba65b 100%);
  padding: 10px 20px;
  opacity: 0.8;
}

#history-a label {
  margin-bottom: 5px;
}

#history-a .form-group {
  margin-bottom: 10px;
}

#history-a .form-control {
  max-width: 400px;
}

#my-account span {
  color: #000;
  font-weight: bold;
}

#my-account h1 {
  font-size: 22px;
  color: #000;
  font-weight: bold;
  margin-top: 20px;
}

.d-flex-center {
  display: flex !important;
  align-items: center;
}

.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

.slider::before {
  position: absolute;
  content: '';
  height: 13px;
  width: 13px;
  left: 4px;
  bottom: 4px;
  background-color: white;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

input:checked + .slider {
  /* background-color: #0000ff; */
  background-color: #6178a1;
  width: 42px;
  min-width: inherit;
}

input:focus + .slider {
  box-shadow: 0 0 1px #2196f3;
}

input:checked + .slider::before {
  -webkit-transform: translateX(19px);
  -ms-transform: translateX(19px);
  transform: translateX(19px);
}

/* Rounded sliders */

.slider.round {
  border-radius: 34px;
  width: 42px;
  min-width: inherit;
}

.slider.round::before {
  border-radius: 50%;
}

.switch {
  position: relative;
  display: inline-block;
  width: 40px;
  height: 20px;
}

.switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

.upload-btn-wrapper {
  position: relative;
  overflow: hidden;
  display: inline-block;
}

.btn-a {
  border: 2px solid gray;
  color: gray;
  background-color: white;
  padding: 8px 20px;
  border-radius: 8px;
  font-size: 20px;
  font-weight: bold;
}

.upload-btn-wrapper input[type='file'] {
  font-size: 100px;
  position: absolute;
  left: 0;
  top: 0;
  opacity: 0;
}

.img-banner img {
  width: 40px;
  height: auto;
}

/* .from-group {
  padding-bottom: 15px;
} */

.btn-file {
  position: relative;
  overflow: hidden;
}

.btn-file input[type='file'] {
  position: absolute;
  top: 0;
  right: 0;
  min-width: 100%;
  min-height: 100%;
  font-size: 100px;
  text-align: right;
  filter: alpha(opacity=0);
  opacity: 0;
  outline: none;
  background: white;
  cursor: inherit;
  display: block;
}

#img-upload {
  width: 250px;
}

.height-100 {
  height: 150px;
  position: relative;
  border: 1px solid #000;
  display: block;
  margin-top: 10px;
  width: 290px;
}

.height-100 p {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 150px;
}

.d-flex {
  display: flex !important;
  align-items: center;
}

.m-t2 {
  margin-top: 3px;
}

.d-flex-right {
  display: inline-flex;
  width: 100%;
  justify-content: flex-end;
}

#Categories th:first-child {
  width: 50px;
}

#Categories td:last-child {
  width: 180px;
}

#add-cat .btn,
#edit-cat .btn,
#send-modal .btn {
  width: 150px;
  max-width: 150px;
}

.nav {
  padding-left: 0;
  margin-bottom: 0;
  list-style: none;
  background: #404e67;
}

#wrapper {
  height: 100%;
  overflow-y: auto;
}

.change-pw-modal {
  color: #000;
  font-size: 16px;
}

.change-pw-modal-a {
  padding-bottom: 7px;
}

.change-pw-modal:hover {
  color: #0071ba;
}

.top-menu2 .top-menu ul li a:hover {
  color: #0000ff;
}

.bth-a {
  width: 100px;
  min-width: 100px !important;
}

/* width */

::-webkit-scrollbar {
  width: 5px !important;
  height: 8px;
}

/* Track */

::-webkit-scrollbar-track {
  background: #f1f1f1 !important;
}

/* Handle */

::-webkit-scrollbar-thumb {
  background: #6178a1 !important;
}

/* Handle on hover */

::-webkit-scrollbar-thumb:hover {
  background: #f15c2b;
}

.mail-text {
  border: 1px solid #666;
}

.note-editor {
  height: auto !important;
  min-height: 203px;
}

.fa-eye {
  margin-right: 5px;
}

#modal-delete h3 {
  font-size: 20px;
  color: #000;
  margin-bottom: 20px;
}

#modal-delete button.close {
  padding-top: -15px !important;
}

#dashboard-head a {
  color: #0000ff;
  text-decoration: none;
}

#dashboard-head .ibox:hover p {
  color: #f15c2b !important;
  text-decoration: none;
}

#dashboard-head .ibox:hover h2 {
  color: #f15c2b !important;
  text-decoration: none;
}

#send-modal h4 {
  color: #000;
  font-size: 16px;
}

body.mini-navbar .nav-header {
  width: 70px;
}

body.mini-navbar .nav-header img {
  width: 70px;
}

.navbar-default {
  overflow-x: auto;
}

/* my theme css */

#sign-in .modal-dialog {
  width: 400px;
}

#sign-in .form-control {
  padding-left: 50px;
}

.btn-primary {
  border-radius: 28px;
}

.img-logo {
  width: 90px;
  height: auto;
}

h2 {
  font-size: 20px;
  color: #0071ba;
  font-weight: bold;
  letter-spacing: 0px;
}

h4 {
  font-weight: bold !important;
}

.custom-checkbox .custom-control-label:before {
  top: 0;
  width: 1.2rem;
  height: 1.2rem;
  border-radius: 0.125rem;
  left: -26px;
}

.custom-checkbox .custom-control-label:after {
  left: -23px;
}

.coman-modal .btn-primary {
  border-radius: 20px;
  background-color: #0000ff;
  background: #0000ff;
  border-color: #0000ff;
  width: 150px;
  max-width: 150px;
}

/* .navbar-form-custom .form-control{
  margin-top: -27px;
}
.count-info .label {
  line-height: 12px;
  padding: 2px 5px;
  position: absolute;
  right: -2px;
  top: -10px;
} */

.minimalize-styl-2 {
  margin-top: -1px;
  border-radius: 0px;
  border-radius: 3px;
}

.nav.navbar-top-links.navbar-right {
  background-color: #fff;
  display: flex;
  align-items: center;
}

.navbar-form-custom .form-control {
  background: transparent;
  border: medium none;
  font-size: 14px;
  height: 34px;
  margin: 0;
  z-index: 2000;
  border-radius: 0px;
  margin: 10px;
}

.navbar-form-custom {
  float: left;
  height: 38px;
  padding: 0;
  width: 226px;
  display: inline-table;
  background: #fff;
  margin-top: 5px;
}

body.mini-navbar .logo-element {
  display: block;
  padding: 0px 0;
}

#top-0 .detail-title-a {
  padding-top: 0px;
  padding-left: 16px;
}

.user-profile img {
  width: 150px !important;
  height: auto;
  border-radius: 50%;
  object-fit: cover;
}

.border-bottoma {
  border-bottom: 1px solid #000;
}

.box-diff {
  background: #fdf5f5;
  /* padding: 0 20px; */
  margin: 0px;
  padding: 20px 0px;
  border: 1px solid #80bbe7;
  border-radius: 5px;
}

.coman-modal .btn-primary:hover,
.coman-modal .btn-primary:focus,
.coman-modal .btn-primary:active,
.coman-modal .btn-primary.active,
.coman-modal open .dropdown-toggle.btn-primary,
.coman-modal .btn-primary:active:focus,
.coman-modal .btn-primary:active:hover,
.coman-modal .btn-primary.active:hover,
.coman-modal .btn-primary.active:focus {
  background-color: #fff;
  background: #fff;
  /*background-c: -moz-linear-gradient(left, #1ba65b  0%, #0b599c  100%);*/
  /* background: -webkit-linear-gradient(left, #1ba65b  0%,#0b599c  100%); */
  /* background: linear-gradient(to right, #f15c2b 0%,#0000ff  100%); */
  border-color: #0000ff;
  /* filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#875914', endColorstr='#875914',GradientType=1 );*/
  color: #0000ff;
  border: 1px solid #0000ff;
}

.box-upper {
  padding-bottom: 10px;
  /* margin-right: 15px; */
  color: #000;
  font-size: 14px;
}

.box-upper span {
  font-weight: bold;
}

.box-upper .active {
  color: green;
}

.box-upper .deactive {
  color: red;
}

.btn-primary:hover,
.btn-primary:focus,
.btn-primary:active,
.btn-primary.active,
.open .dropdown-toggle.btn-primary,
.btn-primary:active:focus,
.btn-primary:active:hover,
.btn-primary.active:hover,
.btn-primary.active:focus {
  /* background-color: transparent; */
  background-color: #6178a1;
  border-color: #404e67;
  color: white;
}

.box-wallet p {
  font-size: 15px;
  margin: 0px;
  color: #ffffff;
  text-align: center;
}

.box-wallet span {
  font-size: 20px;
  font-weight: bold;
  text-align: center;
  display: block;
}

.box-walleta table {
  border: 1px solid #000;
}

#customers {
  font-family: 'Trebuchet MS', Arial, Helvetica, sans-serif;
  border-collapse: collapse;
  width: 100%;
}

#customers td,
#customers th {
  border: 1px solid #ddd;
  padding: 5px 10px;
  background-color: #fff;
}

#customers tr:nth-child(even) {
  background-color: #f2f2f2;
}

#customers tr:hover {
  background-color: #ddd;
}

#customers th {
  padding-top: 5px;
  padding-bottom: 5px;
  text-align: left;
  background-color: #626262;
  color: white;
  white-space: nowrap;
}

.active-green {
  color: green !important;
  font-weight: bold !important;
}

.active-red {
  color: red !important;
}

.tabs-container .nav-tabs > li.active > a,
.tabs-container .nav-tabs > li.active > a:hover,
.tabs-container .nav-tabs > li.active > a:focus {
  border: 1px solid #0000ff;
  border-bottom-color: #0000ff;
  /* background: #0000ff; */
  background: #404e67;
  color: #fff;
  /*margin-bottom: 1px;*/
  font-size: 14px;
  font-weight: bold;
}
.tabs-container .nav-tabs-owner > li.active > a,
.tabs-container .nav-tabs-owner > li.active > a:hover,
.tabs-container .nav-tabs-owner > li.active > a:focus {
  border: 1px solid #0000ff;
  border-radius: 5px 5px 0px 0px;
  border-bottom-color: #0000ff;
  /* background: #0000ff; */
  background: #80bbe7;
  color: #fff;
  /*margin-bottom: 1px;*/
  font-size: 14px;
  font-weight: bold;
}

#tab-new li,
#tab-new1:not(.tab-justify) li {
  width: 33%;
}

#tab-inquiries li {
  width: 25%;
}

.nav-tabs > li > a {
  color: #626262;
  font-weight: 600;
  padding: 10px 15px 10px 16px;
  background: #f5f5f5;
  font-size: 14px;
  font-weight: bold;
  margin-top: 20px;
}
.nav-tabs-owner > li > a {
  color: #626262;
  font-weight: 600;
  padding: 10px 15px 10px 16px;
  background: #f5f5f5;
  font-size: 14px;
  font-weight: bold;
  margin-top: 20px;
}

#tab-new .nav,
#tab-new1 .nav {
  background-color: #fff;
  margin-bottom: 1px;
  border-bottom: 1px solid #404e67;
  display: block;
  align-items: flex-end;
  padding-bottom: 1px;
}

#kyc-doc img {
  margin-bottom: 0px;
}

.box i:not(.upload-remove) {
  opacity: 1;
  color: #000;
  font-size: 50px;
}

#side-menu li:nth-child(6) a {
  height: 49px;
}

.box:hover i {
  opacity: 1;
}

.tabs-container .nav-tabs > li a:hover {
  background: #576e97 !important;
  border-color: #0000ff;
  color: #fff;
}

.tabs-container .panel-body {
  background: #fff;
  border: 1px solid #404e67;
  border-radius: 2px;
  padding: 10px;
  position: relative;
  margin-top: -1px;
}

.btn-default {
  background-color: #0000ff;
  border-color: #0000ff;
  font-size: 16px;
  color: #fff;
  border-radius: 20px;
}

.btn-default:hover,
.btn-default:focus,
.btn-default.active {
  border-color: #0000ff;
  color: #0000ff;
  background: #fff;
}

#kyc-doc .box {
  padding: 0px;
  border: 2px solid #626262;
  /* background: #626262; */
  margin-bottom: 0px;
  border-radius: 0px;
  color: #fff;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
}

#my-table td {
  /*white-space: nowrap;  */
}

div.dataTables_wrapper div.dataTables_paginate ul.pagination,
div.dataTables_wrapper div.dataTables_length label,
div.dataTables_wrapper div.dataTables_filter label,
div.dataTables_wrapper div.dataTables_info {
  font-size: 13px;
}

.ibox-title {
  border-color: #80bbe7;
  padding: 20px;
}

.navy-bg span {
  font-size: 13px;
  padding-bottom: 5px;
  text-align: center;
  display: block;
  border-bottom: 1px solid #e0e5e6;
  color: #000;
}

.navy-bg {
  background-color: #36a5ca;
  color: #666;
  font-weight: bold;
}

.ibox {
  margin-bottom: 15px;
}

.ibox-title h5 {
  font-size: 20px;
  color: #0000ff;
  font-weight: bold;
  width: 100%;
}

.table-hover > tbody > tr:hover {
  background-color: #fdf5f5;
  cursor: pointer;
}

.label-primary,
.badge-primary {
  background-color: #80bbe7;
  color: #ffffff;
  padding: 4px;
  line-height: 12px;
  text-decoration: none;
}

.ibox-title a.label {
  float: right;
}

.table-hover > tbody > tr:hover .label-primary {
  background-color: #0000ff;
}

.mb-10 {
  margin-bottom: 10px;
  word-break: break-all;
}

#sosh-des .detail-title-a span {
  min-width: 164px;
}

.text-muted {
  font-size: 16px;
}

.text-18 {
  font-size: 13px;
  font-weight: bold;
}

.text-18:hover a {
  text-decoration: underline;
}

.feed-element {
  border-bottom: none !important;
  padding-bottom: 0px;
}

#my-tablea th:last-child {
  width: 63px !important;
}

#my-tablea td:last-child,
#my-tablea .action-btn {
  min-width: 63px !important;
}

#img-a .box {
  margin-top: 10px;
}

.feed-element h4 {
  margin-bottom: 0px;
  font-size: 14px !important;
}

.pad-10 {
  padding: 10px;
  margin-bottom: 15px;
}

.pad-r-0 {
  padding-right: 0px;
}

#tab-width li {
  width: 25% !important;
}

.welcome-message {
  font-size: 13px;
}

.dropdown-alerts .text-muted {
  font-size: 12px;
}

.media-body .text-muted {
  font-size: 15px;
}

.chosen-container-single .chosen-single div {
  right: 11px !important;
}

#page-note {
  margin-top: 15px;
  margin-bottom: 0px;
  display: flex;
  align-items: center;
}

#page-note .pagination {
  margin: 0px;
}

#img-a img {
  width: 100%;
  height: 80px;
  object-fit: contain;
}

/*notification*/

#notification .ibox {
  margin-bottom: 0px;
}

#notification .ibox-content {
  padding: 15px;
}

#datepicker .input-group-addon {
  border-top: 1px solid #c3bcbc;
  border-bottom: 1px solid #c3bcbc;
  border-left: 1px solid #c3bcbc;
}

.bor-left {
  border-left: none !important;
}

.input-sm {
  padding: 6px 10px;
}

/*video style*/

.pace-done {
  background-color: #f6f7fb;
}

.with-100 {
  width: 100%;
  max-width: 104px;
}

#timeline td:nth-child(7) {
  white-space: nowrap;
}

.dataTables_wrapper {
  padding-bottom: 10px;
}

.widget.style1 h2 {
  font-size: 20px;
  font-weight: bold;
  color: #000;
}

.minimalize-styl-2.btn-primary {
  background: #404e67;
  color: #fff;
}

#notification .media-body .text-muted {
  font-size: 11px;
}

#notification .feed-activity-list .feed-element {
  border-bottom: 1px solid #e7eaec !important;
  padding-bottom: 10px;
}

#details-a .detail-title-a span {
  width: 80%;
  max-width: 270px;
  min-width: 270px;
}

#hamber h5 {
  padding: 0px !important;
  color: #626262;
  font-size: 14px;
}

#hamber .ibox-title {
  display: flex;
  padding-bottom: 0px;
  border-color: #626262;
  padding: 0px;
  padding-top: 13px;
  padding-left: 8px;
}

#hamber .ibox-tools {
  width: 50px;
  padding-right: 10px;
}

#hamber .ibox-content {
  padding-top: 0px;
  padding-left: 0px;
  padding-right: 0px;
  padding-bottom: 0px;
}

#hamber i {
  color: #626262;
}

#hamber .ibox {
  margin-bottom: 0px;
}

#hamber td {
  vertical-align: inherit;
}

#hamber td:last-child {
  vertical-align: inherit;
  text-align: right;
}

#hamber img {
  width: 20px;
  height: auto;
  border: 1px solid #626262;
  margin-right: 0px;
}

#wrap-a .col-lg-2,
#wrap-a .col-lg-3,
#wrap-a .col-lg-4 {
  padding-right: 0px;
}

#wrap-a .ibox-title {
  padding: 10px;
}

#wrap-a i {
  color: #0000ff;
  font-size: 25px;
}

.dash-class td:last-child {
  text-align: right;
}

.dash-class {
  font-size: 13px;
  margin-bottom: 0px;
  width: 100%;
  font-weight: bold;
}

/*tabs*/

#dashboard-a .nav {
  background-color: #e0e5e6;
}

#dashboard-a .nav-pills > li {
  width: 19%;
  margin-right: 10px;
}

#dashboard-a .nav > li > a {
  background-color: #fff;
  font-size: 18px;
  color: #626262;
  text-align: center;
}

#dashboard-a .nav-pills > li:last-child {
  margin-right: 0px;
}

#dashboard-a .nav > li.active {
  border-left: none;
}

#dashboard-a .nav > li.active a {
  background-color: #626262;
  color: #fff;
}

.bottom {
  margin-bottom: 0px;
}

#sosh-des {
  margin-bottom: 12px;
}

@media (min-width: 1200px) {
  .widthhr .col-lg-3 {
    width: 20%;
  }
  .widthbr .col-lg-3 {
    width: 20%;
  }
}

.sidebard-panel .feed-element,
.media-body,
.sidebard-panel p {
  font-size: 13px;
}

.hover-href td a {
  text-decoration: none;
  color: inherit;
}

.hover-href tr:hover a {
  color: #80bbe7;
  text-decoration: none;
  cursor: pointer;
}

.success-msg-icon .fa-times-circle {
  color: red;
  font-size: 63px;
  margin-top: 10px;
  margin-bottom: 20px;
}

#datepicker .bor-left {
  background-color: transparent;
  border: none;
}

table.dataTable thead .sorting:after,
table.dataTable thead .sorting_asc:after,
table.dataTable thead .sorting_desc:after,
table.dataTable thead .sorting_asc_disabled:after,
table.dataTable thead .sorting_desc_disabled:after {
  position: absolute;
  bottom: 11px !important;
  right: 8px;
  display: block;
  font-family: 'Glyphicons Halflings';
  opacity: 0.5;
}

.clockpicker .input-group-addon {
  border: 1px solid #c3bcbc;
  border-left: 0px;
}

#datepicker1 .input-group-addon {
  border: 1px solid #c3bcbc;
  border-left: 0px;
}

/*Content Area*/

.box_container {
  margin: 0 auto;
  max-width: 650px;
  width: 100%;
  padding: 25px;
  background: #fff;
  border-radius: 6px;
  margin-top: 10px;
  margin-bottom: 70px;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.2);
  position: relative;
  display: inline-block;
  text-align: left !important;
}

.box_container.full {
  max-width: 100%;
}

.dashboard h2 {
  opacity: 0.7;
  text-transform: uppercase;
  font-size: 20px;
}

.dashboard .card_main {
  margin-bottom: 0px;
}

.dashboard .table {
  margin: 0px;
  font-size: 15px;
}

.dashboard .table td:last-child {
  text-align: center;
  /* font-family: 'RobotoMedium'; */
}

.dashboard .table-responsive {
  background: #fff;
  border-radius: 4px;
  padding: 10px;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.18);
}

.dashboard .box_container {
  margin-top: 30px;
  background: none;
  padding: 0px;
  box-shadow: none;
}

.dashboard .card {
  float: left;
  width: 100%;
  margin-top: 15px;
  padding: 15px;
  border-radius: 6px;
  box-shadow: 0px 1px 12px rgba(0, 0, 0, 0.3);
  color: #fff;
  position: relative;
  overflow: hidden;
  /* font-size: 16px; */
  font-size: 20px;
  border-bottom: 4px solid rgba(0, 0, 0, 0.25);
}

.dashboard .card .fa {
  position: absolute;
  right: -10px;
  bottom: -10px;
  font-size: 80px;
  opacity: 0.4;
}

.dashboard .card.four .fa {
  bottom: -15px;
}

.dashboard .card p.count {
  margin-bottom: 0px;
  /*font-family: 'RobotoMedium';*/
  font-size: 34px;
  margin-top: 20px;
}

.dashboard .card.one {
  /* background: #80bbe7; */
  background: linear-gradient(to right, #23c6c8, #3bfdff);
  /* background: -moz-linear-gradient(-45deg, #3bd1bf 0%, #119bd2 100%);
  background: -webkit-linear-gradient(-45deg, #3bd1bf 0%, #119bd2 100%);
  background: linear-gradient(135deg, #3bd1bf 0%, #119bd2 100%);
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#3bd1bf', endColorstr='#119bd2', GradientType=1); */
}

.dashboard .card.two {
  background: linear-gradient(to right, #0ac282, #58ffc5);
  /* background: -moz-linear-gradient(-45deg, #ff875e 1%, #fc629d 100%);
  background: -webkit-linear-gradient(-45deg, #ff875e 1%, #fc629d 100%);
  background: linear-gradient(135deg, #ff875e 1%, #fc629d 100%);
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#ff875e', endColorstr='#fc629d', GradientType=1); */
}

.dashboard .card.three {
  background: linear-gradient(to right, #fe5d70, #ff93a0);

  /* background: -moz-linear-gradient(-45deg, #ee70e9 0%, #8363f9 100%);
  background: -webkit-linear-gradient(-45deg, #ee70e9 0%, #8363f9 100%);
  background: linear-gradient(135deg, #ee70e9 0%, #8363f9 100%);
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#ee70e9', endColorstr='#8363f9', GradientType=1); */
}

.dashboard .card.four {
  background: linear-gradient(to right, #337ab7, #60b5ff);
  /* background: -moz-linear-gradient(-45deg, #f7cd13 1%, #39ce86 100%);
  background: -webkit-linear-gradient(-45deg, #f7cd13 1%, #39ce86 100%);
  background: linear-gradient(135deg, #f7cd13 1%, #39ce86 100%);
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#f7cd13', endColorstr='#39ce86', GradientType=1); */
}

.dashboard .card.five {
  background: #f4ad67;
  background: -moz-linear-gradient(-45deg, #3bd1bf 0%, #119bd2 100%);
  background: -webkit-linear-gradient(135deg, #e68484 0%, #d21167 100%);
  background: linear-gradient(135deg, #e68484 0%, #d21167 100%);
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#3bd1bf', endColorstr='#119bd2', GradientType=1);
}

.alert {
  padding: 10px 15px;
}

a[data-sort='status'] {
  text-align: center;
  display: block;
}

table {
  font-size: 14px;
}

.main {
  width: 100%;
  height: 100%;
  text-align: center;
  display: table;
  padding-left: 310px;
}

.main .container {
  text-align: center;
}

.main .center {
  display: table-cell;
  vertical-align: middle;
  text-align: left;
}

.open .main {
  padding-left: 80px;
}

.prof-img a {
  padding: 0px !important;
}

html,
body {
  height: 100%;
  width: 100%;
  /* overflow: hidden; */
}

.scrollbar-inner {
  height: 100%;
  overflow: auto;
  /* width: 100%; */
  max-height: none;
}

.scroll-wrapper {
  overflow: hidden !important;
  padding: 0 !important;
  position: relative;
}

.scroll-wrapper > .scroll-content {
  border: none !important;
  box-sizing: content-box !important;
  height: auto;
  left: 0;
  margin: 0;
  max-height: none;
  max-width: none !important;
  overflow: scroll !important;
  padding: 0;
  position: relative !important;
  top: 0;
  width: auto !important;
}

.scroll-wrapper > .scroll-content::-webkit-scrollbar {
  height: 0;
  width: 0;
}

.scroll-element {
  display: none;
}

.scroll-element.scroll-x.scroll-scrollx_visible,
.scroll-element.scroll-y.scroll-scrolly_visible {
  display: block;
}

.scroll-element .scroll-bar,
.scroll-element .scroll-arrow {
  cursor: default;
}

.scrollbar-inner > .scroll-element,
.scrollbar-inner > .scroll-element div {
  background: none;
  border: none;
  margin: 0;
  padding: 0;
  position: absolute;
  z-index: 10;
}

.scrollbar-inner > .scroll-element div {
  display: block;
  height: 100%;
  left: 0;
  top: 0;
  width: 100%;
}

.scrollbar-inner > .scroll-element.scroll-x {
  bottom: 2px;
  height: 7px;
  left: 0;
  min-width: 100%;
  width: 100%;
}

.scrollbar-inner > .scroll-element.scroll-y {
  height: 100%;
  min-height: 100%;
  right: 2px;
  top: 0;
  width: 7px;
}

.scrollbar-inner > .scroll-element .scroll-element_outer {
  opacity: 0.3;
  -webkit-border-radius: 12px;
  -moz-border-radius: 12px;
  border-radius: 12px;
}

.scrollbar-inner > .scroll-element .scroll-element_size {
  background-color: #cccccc;
  display: none;
  opacity: 1;
  -webkit-border-radius: 12px;
  -moz-border-radius: 12px;
  border-radius: 12px;
  -webkit-transition: opacity 0.2s;
  -moz-transition: opacity 0.2s;
  -o-transition: opacity 0.2s;
  -ms-transition: opacity 0.2s;
  transition: opacity 0.2s;
}

.scrollbar-inner > .scroll-element .scroll-bar {
  background-color: #2c2e38;
  -webkit-border-radius: 7px;
  -moz-border-radius: 7px;
  border-radius: 7px;
}

.scrollbar-inner > .scroll-element.scroll-x .scroll-bar {
  bottom: 0;
  height: 7px;
  min-width: 24px;
  top: auto;
}

.scrollbar-inner > .scroll-element.scroll-y .scroll-bar {
  left: auto;
  min-height: 24px;
  right: 0;
  width: 7px;
}

.scrollbar-inner > .scroll-element.scroll-x .scroll-element_outer {
  bottom: 0;
  top: auto;
  left: 2px;
  -webkit-transition: height 0.2s;
  -moz-transition: height 0.2s;
  -o-transition: height 0.2s;
  -ms-transition: height 0.2s;
  transition: height 0.2s;
}

.scrollbar-inner > .scroll-element.scroll-y .scroll-element_outer {
  left: auto;
  right: 0;
  top: 2px;
  -webkit-transition: width 0.2s;
  -moz-transition: width 0.2s;
  -o-transition: width 0.2s;
  -ms-transition: width 0.2s;
  transition: width 0.2s;
}

.scrollbar-inner > .scroll-element.scroll-x .scroll-element_size {
  left: -4px;
}

.scrollbar-inner > .scroll-element.scroll-y .scroll-element_size {
  top: 0px;
}

/* Track order */

.list-inline {
  padding-left: 0;
  margin-left: -5px;
  list-style: none;
}

.track_ship_flow ul li {
  width: 20%;
  float: left;
  text-align: center;
  position: relative;
  font-size: 14px;
}

.track_ship_flow ul li span {
  width: 50px;
  height: 50px;
  border-radius: 100%;
  display: inline-block;
  margin: 10px auto;
  text-align: center;
  line-height: 48px;
  box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.3);
  color: #333;
}

.successtrack span {
  background: #62b662;
}

.track_ship_flow ul li p {
  margin: 0px;
}

.track_date {
  float: left;
  width: 100%;
  text-align: center;
  font-size: 13px;
  color: #777;
  margin: 0px;
}

.track_ship_flow ul li + li.successtrack:before {
  background: #62b662;
}

.track_ship_flow ul li + li:before {
  position: absolute;
  top: 35px;
  left: -35.5%;
  height: 2px;
  content: '';
  width: 71%;
}

.track_ship_flow ul li + li.ongoing:before {
  background: #ef7185;
}

.ongoing span {
  background: #ef7185;
}

.track_ship_flow i.fa {
  line-height: 50px;
  color: #fff;
  font-size: 22px;
}

.wid100 {
  width: 100%;
  float: left;
}

.acc_rules {
  background: #eee;
  border: 1px solid #ddd;
  padding: 6px 5px;
  border-radius: 3px;
  float: left;
  width: 100%;
}

.acc_rules label {
  display: inline-block;
}

.acc_rules .label.label-success {
  height: 26px;
  color: #fff;
  font-size: 13px;
  border-radius: 0;
  line-height: 26px;
  padding: 0;
}

.listTree ul {
  padding: 0;
}

#home-top {
  position: fixed;
  bottom: 20px;
  right: 10px;
  z-index: 6;
}

a#subscribe,
a#unsubscribe {
  background: #c5c5c5 !important;
  box-shadow: none;
  border-radius: 100px;
  width: 36px;
  height: 36px;
  min-width: auto;
  padding: 0;
}

a#subscribe i.fa {
  color: #0b9243;
  font-size: 20px;
  text-align: center;
  padding: 8px 0 6px 0;
  width: 100%;
}

a#unsubscribe i.fa {
  font-size: 20px;
  text-align: center;
  padding: 8px 0 6px 0;
  width: 100%;
}

a#unsubscribe {
  margin-right: 10px;
}

#loader {
  position: fixed;
  height: 100%;
  width: 100%;
  background: #fff;
  z-index: 99999;
  left: 0px;
  top: 0px;
}

.sideclick .disin label.switch {
  width: 60px;
}

.part-assign {
  display: inline-block;
  width: 100%;
  margin: 20px 0;
  background: #ffffff;
  padding: 20px;
  border-radius: 4px;
  box-shadow: 0px 0px 20px 0px #bbb;
}

.part-assign .links,
.custom_link {
  font-size: 14px;
  text-decoration: underline;
  color: #fc7f19;
}

/* update scrollbar offset if both scrolls are visible */

.scrollbar-inner
  > .scroll-element.scroll-x.scroll-scrolly_visible
  .scroll-element_size {
  left: -11px;
}

.scrollbar-inner
  > .scroll-element.scroll-y.scroll-scrollx_visible
  .scroll-element_size {
  top: -11px;
}

.scrollbar-inner > .scroll-element:hover .scroll-element_outer,
.scrollbar-inner > .scroll-element.scroll-draggable .scroll-element_outer {
  overflow: hidden;
  -ms-filter: 'progid:DXImageTransform.Microsoft.Alpha(Opacity=70)';
  filter: alpha(opacity=70);
  opacity: 0.7;
}

.scrollbar-inner
  > .scroll-element:hover
  .scroll-element_outer
  .scroll-element_size,
.scrollbar-inner
  > .scroll-element.scroll-draggable
  .scroll-element_outer
  .scroll-element_size {
  opacity: 1;
}

.scrollbar-inner > .scroll-element:hover .scroll-element_outer .scroll-bar,
.scrollbar-inner
  > .scroll-element.scroll-draggable
  .scroll-element_outer
  .scroll-bar {
  height: 100%;
  width: 100%;
  -webkit-border-radius: 12px;
  -moz-border-radius: 12px;
  border-radius: 12px;
}

.scrollbar-inner > .scroll-element.scroll-x:hover .scroll-element_outer,
.scrollbar-inner
  > .scroll-element.scroll484-x.scroll-draggable
  .scroll-element_outer {
  height: 20px;
  min-height: 7px;
}

.scrollbar-inner > .scroll-element.scroll-y:hover .scroll-element_outer,
.scrollbar-inner
  > .scroll-element.scroll-y.scroll-draggable
  .scroll-element_outer {
  min-width: 7px;
  width: 12px;
}

.site_menu .scrollbar-inner > .scroll-element .scroll-bar,
.sub_menu .scrollbar-inner > .scroll-element .scroll-bar {
  background: #fff;
}

.site_menu .scrollbar-inner > .scroll-element.scroll-y .scroll-bar,
.sub_menu .scrollbar-inner > .scroll-element.scroll-y .scroll-bar {
  width: 7px !important;
}

/* .site_menu .scroll-element_outer, .sub_menu .scroll-element_outer{opacity:0 !important; transition:0.2s !important}
.site_menu:hover .scroll-element_outer, .sub_menu:hover .scroll-element_outer{opacity:0.2 !important} */

.scrollbar-inner {
  overflow-y: auto;
  position: fixed !important;
  overflow-x: hidden !important;
}

.scrollbar-inner:hover {
  overflow: auto;
}

.top_action h5 {
  font-size: 16px;
}

/*hungery bunny order circlle*/

.colours_action {
  display: inline-block;
  width: 100%;
  text-align: center;
  padding: 15px 0 15px;
  background: #e7f7f7;
  border-radius: 3px;
  margin-bottom: 30px;
  margin-top: 0px;
  box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.18);
}

.colours_action ul li {
  display: inline-block;
  list-style: inherit;
  margin: 8px 5px;
  min-width: 16% !important;
  border: none !important;
  width: auto !important;
}

.deliver_section {
  display: inline-block;
  width: 100%;
  text-align: center;
  padding: 15px 0 15px;
  /* background: #e7f7f7; */
  border-radius: 3px;
  margin-bottom: 30px;
  margin-top: 0px;
  /* box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.18); */
}
.deliver_section ul li {
  display: inline-block;
  list-style: inherit;
  margin: 8px 5px;
  min-width: 16% !important;
  border: none !important;
  width: auto !important;
}
.icons-circle-number {
  display: inline-block;
  width: 100%;
  height: 60px;
  line-height: 60px;
  border-radius: 10px;
  color: #fff;
  font-size: 20px;
  box-shadow: 0px 0px 20px 0px rgba(0, 0, 0, 0.15);
}

.deliver_section ul li h4 {
  margin: 11px 0;
  font-size: 16px;
  margin-bottom: 0px;
  color: #333;
}

.deliver_section ul {
  margin-bottom: 0px;
  padding: 0px;
}

.deliver_section .orange {
  background: #80bbe7;
}

.deliver_section .green {
  /* background: #0000ff; */
  background: linear-gradient(to right bottom, #6d6dff, #7676f1);
}

.deliver_section .blue {
  background: #9c5b57;
}

.deliver_section .dark-green {
  /* background: #8c41cd; */
  background: linear-gradient(to right bottom, #8c41cd, #b66ef5);
}

.deliver_section .red {
  /* background: #80bbe7; */
  background: linear-gradient(to right bottom, #40a5f1, #85c3f3);
}

.deliver_section .pink {
  background: #0000ff;
}

.deliver_section .violet {
  /* background: #9c5b57; */
  background: linear-gradient(to right bottom, #cd726c, #e57e77);
}

#color .deliver_section ul li {
  min-width: 13% !important;
}
.deliveryService .deliver_section ul li {
  min-width: 17% !important;
}
.transportHistory .deliver_section ul li {
  min-width: 17% !important;
}

.colours_actions ul li h4 {
  margin: 11px 0;
  font-size: 16px;
  margin-bottom: 0px;
  color: #333;
}

.colours_action ul {
  margin-bottom: 0px;
  padding: 0px;
}

.colours_action .orange {
  background: #80bbe7;
}

.colours_action .green {
  /* background: #0000ff; */
  background: linear-gradient(to right bottom, #6d6dff, #7676f1);
}

.colours_action .blue {
  background: #9c5b57;
}

.colours_action .dark-green {
  /* background: #8c41cd; */
  background: linear-gradient(to right bottom, #8c41cd, #b66ef5);
}

.colours_action .red {
  /* background: #80bbe7; */
  background: linear-gradient(to right bottom, #40a5f1, #85c3f3);
}

.colours_action .pink {
  background: #0000ff;
}

.colours_action .violet {
  /* background: #9c5b57; */
  background: linear-gradient(to right bottom, #cd726c, #e57e77);
}

#color .colours_action ul li {
  min-width: 13% !important;
}
.deliveryService .colours_action ul li {
  min-width: 17% !important;
}
.transportHistory .colours_action ul li {
  min-width: 17% !important;
}

.action-btn a {
  font-size: 14px;
  margin-right: 10px;
  color: #0000ff;
  text-decoration: none;
}

.action-btn i {
  padding: 5px;
  background-color: #f7f7f7;
  border-radius: 5px;
  border: 1px solid;
}

#sidetabs .nav-tabs > li > a {
  margin-top: 0px;
}

#sidetabs .nav-tabs > li {
  width: 100%;
}

.section1 {
  /* background-color: #f8f8f8; */
  padding: 20px;
  margin-top: 15px;
}

.img-bot {
  display: flex;
  justify-content: space-between;
  padding: 0px !important;
}

#tab-costum .detail-title-a {
  padding: 0 15px;
  padding-top: 15px;
}

#tab-costum .detail-title-a p {
  margin-bottom: 10px;
}

#tab-costum label {
  margin-right: 10px;
}

#tab-costum input {
  margin-right: 10px;
}

#tab-costuma .detail-title-a span {
  width: 60%;
  max-width: 250px;
  min-width: 250px;
}

#img-a img {
  width: 100%;
  height: auto;
}

.p-b-20 {
  padding-bottom: 20px !important;
}

#owner-detail-btn2 .action-btn {
  width: 150px;
  min-width: 150px;
}

#owner-detail-btn2 th:last-child {
  width: 150px;
  min-width: 150px;
}

#owner-detail-btn2 .fa-eye {
  margin-right: 0px;
}

#trackDeliveryBoy .modal-lg {
  width: 100%;
  max-width: 900px;
}

#trackDeliveryBoy .modal-body {
  padding: 0px;
}

#trackDeliveryBoy .modal-header {
  padding: 0px;
}

#trackDeliveryBoy h4 {
  font-size: 24px;
  padding-bottom: 10px;
}

#trackDeliveryBoy h1 {
  font-size: 20px;
  border-bottom: 1px solid #f1ebe9;
  padding-bottom: 10px;
}

.kyc-top-bar .box i {
  opacity: 1;
  position: absolute;
  left: 32%;
  top: 32%;
  font-size: 24px;
}

#trackDeliveryBoy p {
  margin-bottom: 5px;
  margin-top: 10px;
}

#trackDeliveryBoy .detail-title-a {
  padding: 0px;
}

/* 31-10-17 */

.printview-order-inner table p {
  margin-bottom: 0px;
}

.printview-order-inner table tr td {
  padding: 5px 0px;
  border: none;
}

.printview-order-inner table tr td {
  /* display: inline-table; */
  margin-bottom: 10px;
  max-width: 490px;
  width: 50%;
}

.printview-order-inner table tr td:first-child {
  min-width: 150px;
}

.printview-order-inner .sub_title {
  border-bottom: 1px solid #ccc;
  padding-bottom: 10px;
  font-size: 18px;
}

#printpreview .modal-small {
  width: 720px;
}

#Edit-modal .modal-small {
  width: 720px;
}

.total_box .tbl_view {
  width: 100%;
  margin-bottom: 0px;
  min-width: 220px;
}

.tbl_view {
  display: table;
  padding: 0px;
  margin: 0;
  border-collapse: collapse;
  width: 100%;
}

.tbl_view li {
  display: table-row;
  text-align: left;
}

.total_box .tbl_view li {
  border-top: 1px solid #ccc;
}

.total_box .tbl_view li:first-child {
  border-top: 0px;
}

.tbl_view li .td {
  display: table-cell;
  padding: 4px 10px;
  vertical-align: middle;
}

.total_box .tbl_view li .td {
  padding: 3px 3px;
}

.total_box .total {
  color: #fff;
}

.printview-order-inner .sub_title button {
  float: right;
  margin-top: -15px;
}

.printleft {
  float: right;
  margin-top: 10px;
}

.total_box .tbl_view li .td.lbl {
  text-align: right;
}

.printview-order-inner.order_details {
  background: #e8fff8;
  padding: 10px;
}

.printview-order-inner.order_details table {
  margin-bottom: 30px;
}

.innerpricing tr td {
  padding: 5px 2px;
}

.ing .tbl_view li span {
  padding: 4px 4px;
}

.ing .tbl_view li span.td.lbl {
  text-align: right;
}

.overallTable th,
.overallTable td {
  text-align: center;
}

.printview-order-inner.assigndeliverboy {
  background: #f5f5f5;
  padding: 10px;
  position: relative;
  margin-bottom: 20px;
  display: inline-block;
  width: 100%;
  border-radius: 3px;
  border: 1px solid #e2e1e1;
}

.printview-order-inner.assigndeliverboy table {
  margin-bottom: 0px;
}

.printview-order-inner .sub_title {
  width: 100%;
  display: inline-block;
}

.assigndeliverboy.printview-order-inner .sub_title button {
  margin-top: 0px;
  float: right;
}

.assigndeliverboy.printview-order-inner .sub_title {
  line-height: 42px;
  color: #333;
}

.printview-order-inner.assigndeliverboy.offlinedel {
  background: #fde6e6;
}

.printview-order-inner.assigndeliverboy.onlinedel {
  background: #e8f9eb;
}

.nodleiveryboy {
  text-align: center;
  background: #fde6e6;
  padding: 16px;
  border: 1px solid #ccc;
  margin-bottom: 24px;
}

.nodleiveryboy p {
  margin-bottom: 0px;
  font-size: 16px;
  font-weight: bold;
  line-height: 35px;
  color: #333;
}

#printpreview .modal-body,
#Edit-modal .modal-body {
  padding: 0px;
}

.modal-header {
  padding: 0px;
  padding-bottom: 10px;
}

.modal-title {
  font-size: 22px;
  padding: 0px;
}

.modal textarea {
  white-space: normal;
  word-spacing: inherit;
  padding-left: 10px !important;
}

.ecommerce .fa-star,
.ecommerce .fa-star-half,
.ecommerce .fa-star-half-o,
.ecommerce .fa-star-o {
  color: #68c143;
}

#setting .form-group {
  display: flex;
  align-items: center;
}

#setting .row {
  margin-bottom: 8px;
}

#setting-col .col-lg-4,
#setting-col label {
  margin: 10px 0px;
}

.icon.ic-3 {
  background-color: #9e9696;
}

/*Radio*/

input.radio {
  display: none;
}

input[type='radio'] + label.radio {
  cursor: pointer;
  position: relative;
  padding-left: 24px;
  margin: 0px;
  clear: both;
  display: inline-block;
  line-height: 18px;
  text-align: left;
}

input[type='radio'] + label.radio:before {
  content: '';
  width: 18px;
  height: 18px;
  position: absolute;
  top: 0px;
  left: 0px;
  font-family: FontAwesome;
  text-align: center;
  color: #fff;
  font-size: 0px;
  border-radius: 100px;
  border: 2px solid #8a8f95;
  line-height: 15px;
}

input[type='radio']:checked + label.radio:before {
  content: '\f00c';
  border: 2px solid #60c138;
  font-size: 11px;
  color: #fff;
  background: #60c138;
}

input[type='radio']:checked + label.radio {
  color: #60c138;
}

.radio_group input[type='radio'] + label.radio,
.checkbox_group input[type='checkbox'] + label.checkbox {
  margin: 5px 15px 5px 0px;
  float: left;
  clear: none;
}

.radio_group span.label,
.checkbox_group span.label,
.checkbox_group label.label,
.radio_group label.label,
.form-group label.label {
  color: #333;
  display: inline-block;
  padding: 0px;
  font-size: 14px;
  font-weight: normal;
  line-height: normal;
  color: #333;
  text-align: left;
  white-space: normal;
  vertical-align: baseline;
  border-radius: 0px;
  margin-right: 10px;
}

.checkbox_group label.label,
.radio_group label.label,
.radio_switch .label,
.form-group label.label,
.label_style label {
  display: block;
  margin-bottom: 10px;
  color: #6c6c6c;
}

.nav.navbar-top-links.navbar-right {
  justify-content: flex-end;
}

.d-flex-a {
  display: flex;
  justify-content: center;
  align-items: center;
}

.navbar-top-links ul {
  margin-bottom: 0px;
}

.navbar-top-links li:last-child {
  margin-right: 20px;
}

#add-cat .btn,
#edit-cat .btn,
#send-modal .btn,
#add-items .btn,
#Edit-cat .btn,
#edit-items .btn {
  width: 150px;
  max-width: 150px;
}

#profile span {
  font-size: 16px;
  color: #676a6c;
  font-weight: bold;
}

.navbar-right {
  float: right !important;
  margin-right: -15px;
}

.width-150a {
  position: absolute;
  z-index: 8888;
}

.ibox {
  -webkit-box-shadow: 2px 10px 21px -5px rgba(0, 0, 0, 0.75);
  -moz-box-shadow: 2px 10px 21px -5px rgba(0, 0, 0, 0.75);
  box-shadow: 2px 10px 21px -5px rgb(204 190 190 / 75%);
}

.ibox-title {
  border-color: #0000ff;
  padding: 15px;
  border-width: 3px 0 0;
  min-height: 125px;
}

.ibox-title h5 {
  /* display: inline-block; */
  /* font-size: 14px; */
  /*margin: 0 0 7px; */
  /* padding: 0; */
  /* text-overflow: ellipsis; */
  float: none;
  margin: 0 0 0px;
  font-weight: bold;
  font-weight: bold;
  display: flex;
  align-items: flex-start;
}

.ibox-title p {
  font-weight: bold;
  font-size: 16px;
  margin-bottom: 0px;
  margin-top: 5px;
  margin-left: 40px;
}

.ibox-title p.my-name {
  padding: 0px;
  font-weight: normal;
  font-size: 14px;
}

#profile .nav.nav-tabs {
  background: transparent;
}

/* #profile .nav.nav-tabs li {
  width: auto;
} */

.nav-tabs > li.active > a,
.nav-tabs > li.active > a:hover,
.nav-tabs > li.active > a:focus {
  background-color: #fff;
}

.box-title {
  display: flex;
  justify-content: space-between;
}

.half-box {
  padding: 10px;
  background: #0000ff0f;
  border: 1px solid #fff;
  margin-bottom: 20px;
  border-left: 3px solid #0000ff;
  box-shadow: 1px 2px 3px #ddd;
}

#tab-new1:not(.tab-justify) li a {
  height: 62px;
}

#tab-new1 .nav.nav-tabs {
  flex-wrap: wrap;
}

#tab-new1 > .nav-tabs > li > a {
  margin-top: 0;
}
#tab-new1 > .nav-tabs-owner > li > a {
  margin-top: 0;
}

.dashbordstat {
  padding-top: 30px;
}

.noDocFiles {
  color: #000;
  font-size: 14px;
  display: flex;
  margin: 0 0 5px;
  word-break: break-all;
  padding-left: 16px;
}

/* .rc-rate {
  display: flex;
  list-style: none;
}

.rc-rate:hover {
  display: flex;
  list-style: none;
  cursor: pointer;
}

.rc-rate-star-second {
   display: none;
}

.rc-rate-star .rc-rate-star-full {
  color: red
} */

.rc-rate {
  margin: 0;
  padding: 0;
  list-style: none;
  font-size: 18px !important;
  font-weight: bold !important;
  display: inline-block;
  vertical-align: middle;
  font-weight: normal;
  font-style: normal;
  outline: none;
  /* width: 100%; */
}

.rc-rate li {
  width: auto !important;
  border: none !important;
}

.rc-rate li:focus,
.rc-rate li div:focus {
  outline: none;
}

.rc-rate-star {
  margin: 0;
  padding: 0;
  display: inline-block;
  margin-right: 8px;
  position: relative;
  transition: all 0.3s;
  color: #626262;
  cursor: pointer;
  line-height: 1.5;
}

.rc-rate-star-first {
  position: absolute;
  left: 0;
  top: 0;
  width: 50%;
  height: 100%;
  overflow: hidden;
  opacity: 0;
}

.rc-rate-star-first,
.rc-rate-star-second {
  transition: all 0.3s;
}

.rc-rate-star-half .rc-rate-star-first,
.rc-rate-star-full .rc-rate-star-second {
  color: #0000ff;
}
.nav-tabs.nav-justified > li > a {
  border: none !important;
  margin-right: 2px;
}

input[type='file']::file-selector-button {
  border: 2px solid #4b4bff;
  padding: 0.3em 0.5em;
  color: white;
  border-radius: 0.2em;
  background-color: #4b4bff;
}
.vehicle-photo-preview {
  width: 320px !important;
  height: 180px !important;
  object-fit: cover !important;
  padding: 0.75rem;
}

.invoice {
  margin-left: 14px;
}

.add-account-modal {
  width: 800px !important;
}

@media (min-width: 320px) and (max-width: 900px) {
  .add-account-modal {
    width: auto !important;
  }
}
.logisticShipping {
  /* display: flex !important; */
  display: flow-root !important;

  justify-content: space-between !important;
}
.shippingImage {
  height: 30rem !important;
  width: 30rem !important;
}
.acceptOrder {
  display: flex !important;
  justify-content: end !important;
}
.acceptOrder button {
  margin-bottom: 20px !important;
}

.chefButtons {
  display: flex;
  justify-content: space-around;
}

.chefButtons .btn{
  margin: 20px!important;
}
