@media screen and (max-width: 1360px) {
  .banner-box .text {
    left: 75px;
  }
  .banner-box .text h2 {
    font-size: 50px;
  }
  .newly-section {
    padding: 60px 0 30px 0;
  }
  .newly-top-form {
    margin-bottom: 50px;
  }
  .title-def {
    margin-bottom: 40px;
  }
  .title-def h2 {
    font-size: 40px;
  }
  .newly-section-2 {
    padding-top: 100px;
  }
  /*admin responisve*/
  .ico-details table tr td p {
    max-width: 100px;
  }
  .property-dtl-tab .nav li {
    padding: 0 20px 0 0;
  }
  #tab-ibox a {
    padding: 0px;
  }
  .coman-table table tr th {
    font-size: 13px !important;
    line-height: 23px;
  }
  #owner-detail-btn th:last-child {
    width: 50px !important;
  }
}

@media screen and (max-width: 1200px) {
  .header-page {
    padding: 20px 50px 20px 50px;
  }
  .banner-box .text {
    padding: 30px;
    top: 180px;
    left: 75px;
  }
  .banner-box .text h2 {
    font-size: 40px;
    padding: 0 0 20px 0;
  }
  .banner-box .text p {
    font-size: 20px;
    line-height: 32px;
    padding: 0;
  }
  .newly-list .box .text .newly-info h5 {
    padding: 0 20px 0px 0;
  }
  .banner-box > img {
    object-fit: cover;
  }
  .property-dtl-top-section {
    padding: 50px 0 0 0;
  }
  /*admin responisve*/
  .ico-details table tr td p {
    max-width: 100px;
  }
  .chk {
    position: relative;
    float: left;
    margin: 3px 15px 0 0;
  }
}

/*admin responisve*/

@media screen and (max-width: 1023px) {
  .header-page {
    padding: 20px 30px 20px 30px;
  }
  .banner-box .text p {
    font-size: 20px;
    line-height: 30px;
  }
  .banner-box .text {
    left: 80px;
    top: 120px;
    padding: 20px;
  }
  .banner-box .text h2 {
    font-size: 28px;
    padding: 0 0 15px 0;
  }
  .banner-box .text p {
    font-size: 18px;
    line-height: 26px;
  }
  .newly-section {
    padding: 50px 0 20px 0;
  }
  .newly-top-form {
    margin-bottom: 40px;
  }
  .title-def h2 {
    font-size: 24px;
  }
  .banner-slider .carousel-control.left {
    left: 15px;
  }
  .banner-slider .carousel-control.right {
    right: 15px;
  }
  .newly-section-2 {
    padding-top: 100px;
  }
  .property-dtl-top-section h2 {
    font-size: 26px;
  }
  .property-dtl-top-section h3 {
    font-size: 30px;
  }
  .land-info-list ul li .box {
    width: 150px;
    padding: 10px 10px 10px 10px;
    font-size: 16px;
  }
  .neg-left h5 {
    font-size: 20px;
  }
  .neg-left {
    width: 250px;
  }
  .property-dtl-tab .nav li {
    padding: 0 10px 0 0;
  }
  #tab-ibox a {
    padding: 0px;
  }
}

@media screen and (max-width: 992px) {
}

@media screen and (max-width: 768px) {
  nav.navbar-static-top .navbar-right {
    margin-right: 0px;
  }
  .kyc-status {
    padding: 10px;
  }
  .kyc-status img {
    width: 150px;
  }
  .kyc-status h3 {
    font-size: 25px;
    line-height: 30px;
  }
  .box-heading h2 {
    font-size: 20px;
    line-height: 34px;
  }
  .kyc-section h2 {
    font-size: 26px;
  }
  #tab-costuma .detail-title-a {
    padding-top: 0px;
  }
  #tab-costum .nav.nav-tabs li {
    width: 25% !important;
  }
  #setting .nav.nav-tabs li {
    width: 33% !important;
  }
  #tab-new1 .nav {
    flex-wrap: wrap;
  }
  #printpreview .modal-small,
  #Edit-modal .modal-small,
  #trackDeliveryBoy .modal-lg {
    width: 100%;
    /* margin: 20px; */
    padding: 20px;
  }
  #kyc-doc .col-md-4 {
    margin-bottom: 15px;
  }
  .text-right.m-t-sm {
    margin-bottom: 10px;
  }
  #kyc-doc {
    margin-top: 10px;
  }
  #notification .d-flex-right {
    margin-bottom: 20px;
  }
  .property-dtl-tab .nav li {
    padding: 0 10px 0 0;
  }
  #tab-ibox a {
    padding: 0px;
  }
  .admin-box-heading h2 {
    font-size: 19px;
  }
  .table-responsive {
    border: 0px;
  }
  #history h3 {
    font-size: 19px;
  }
  .nav-tabs > li > a {
    margin-top: 0px;
  }
  .nav-tabs-owner > li > a {
    margin-top: 0px;
  }
  .nav-tabs > li {
    width: 100%;
  }
  .user-profile {
    margin-bottom: 15px;
  }
  #sosh-des .detail-title-a span {
    min-width: 201px;
  }
  .pad-r-0 {
    padding-right: 15px;
  }
  div.dataTables_wrapper div.dataTables_length select {
    width: 75px;
    display: inline-block;
    margin-bottom: 5px;
  }
  .dataTables_length {
    float: none;
    margin-bottom: 10px;
  }
  #tab-costum .detail-title-a {
    padding-top: 0px;
  }
  .detail-title-a.section1.p-b-20 {
    margin-bottom: 10px;
  }
  .printview-order-inner.order_details table {
    margin-bottom: 0px;
  }
  .img-bot {
    padding-top: 15px !important;
  }
}

@media screen and (max-width: 767px) {
  #tab-content-a .ibox-content {
    margin-bottom: 20px;
  }
  .overallTable th,
  .overallTable td {
    width: 100% !important;
    min-width: auto !important;
    max-width: 100% !important;
  }
  .coman-modal .modal-content > img {
    width: 76px;
  }
  .dataTables-example {
    width: 100%;
    min-width: 600px;
  }
  .kyc-top-bar .box i {
    opacity: 1;
    position: absolute;
    left: 32%;
    top: 32%;
    font-size: 19px;
  }
  .dataTables_info {
    padding-bottom: 10px;
  }
  div.table-responsive
    > div.dataTables_wrapper
    > div.row
    > div[class^='col-']:first-child {
    padding-left: 15px !important;
  }
  div.table-responsive
    > div.dataTables_wrapper
    > div.row
    > div[class^='col-']:last-child {
    padding-right: 15px !important;
  }
  .modal-a {
    top: 45%;
    transform: translateY(-45%) !important;
    overflow-y: hidden;
  }
  nav.navbar-static-top .navbar-right {
    float: right;
  }
  .header-page {
    position: static;
    padding: 15px;
  }
  .banner-box .text {
    padding: 15px;
    width: auto;
    right: 80px;
    top: 30px;
  }
  .banner-box .text::before {
    display: none;
  }
  .banner-box .text::after {
    display: none;
  }
  .banner-box .text h2 {
    font-size: 22px;
    padding: 0 0 10px 0;
  }
  .banner-box .text p {
    font-size: 16px;
    line-height: 22px;
  }
  .top-right-header {
    position: relative;
  }
  .header-page .top-menu {
    position: absolute;
    top: 100%;
    right: 0;
    left: 0;
    float: none;
    background: #22c0a1;
    z-index: 20;
    padding: 10px 15px;
    margin-top: 5px;
  }
  .header-page .top-menu ul li {
    padding: 0;
    float: none;
  }
  .header-page .top-menu ul li a {
    display: block;
    padding: 5px 15px;
  }
  .header-page .top-menu ul li a:hover {
    background: #ffffff;
    color: #22c0a1;
  }
  .header-page .top-menu ul li ul {
    position: static;
    margin: 0 0 0 15px;
    display: block;
    background: none;
    padding: 0;
    min-width: inherit;
  }
  .header-page .top-menu ul li ul li {
    padding: 0;
  }
  .header-page .navbar-header {
    float: right;
  }
  .login-btn {
    float: left;
  }
  .top-right-header {
    padding: 5px 0 0 0;
  }
  .logo {
    width: 74px;
  }
  .header-page .navbar-toggle {
    margin: 0 0 0 10px;
    padding: 12px 12px 11px 12px;
  }
  .navbar-toggle .icon-bar {
    display: block;
    width: 22px;
    height: 2px;
    border-radius: 1px;
    background-color: #ffffff;
  }
  .banner-slider .carousel-control.left {
    left: 0;
  }
  .banner-slider .carousel-control.right {
    right: 0;
  }
  .newly-section {
    padding: 30px 0 10px 0;
  }
  .title-def {
    margin-bottom: 20px;
  }
  .newly-top-form {
    margin-bottom: 30px;
  }
  .foot-menu {
    text-align: center;
    float: none;
  }
  .foot-menu ul li {
    float: none;
    display: inline-block;
    padding: 0 10px;
  }
  .foot-menu ul li:first-child {
    padding-left: 10px;
  }
  .footer-page {
    padding: 20px 0;
  }
  .copy-right {
    text-align: center;
    float: none;
    padding: 14px 0 0 0;
  }
  .newly-top-form {
    padding: 15px 15px 0 15px;
  }
  .newly-list .box .text {
    padding: 15px;
  }
  .modal-width-600 {
    width: auto !important;
  }
  .property-dtl-top-section {
    padding: 30px 0 0 0;
  }
  .properties-details-sliders .slick-prev {
    margin-top: 0;
  }
  .properties-details-sliders .slick-next {
    margin-top: 0;
  }
  .property-dtl-top-section h2 {
    font-size: 20px;
    padding: 0 0 10px 0;
  }
  .property-dtl-top-section p {
    font-size: 16px;
  }
  .dl-left {
    float: none;
    padding: 0 0 10px 0;
  }
  .dl-right {
    float: none;
    text-align: left;
    padding: 0 0 10px 0;
  }
  .property-dtl-top-section h3 {
    font-size: 20px;
    padding: 0 0 10px 0;
  }
  .land-info-list ul {
    margin: 0 0 0 -15px;
  }
  .land-info-list ul li {
    padding: 0 0 15px 15px;
  }
  .property-dtl-tab .tab-content p {
    line-height: 24px;
    padding: 0 0 20px 0;
  }
  .property-dtl-tab .nav {
    border: none;
  }
  /*.property-dtl-tab .nav li{ float: none; display: block; padding: 0; }*/
  .property-dtl-tab .nav li a {
    display: block;
    padding: 10px 15px 8px 15px;
    background: rgba(0, 0, 0, 0.7);
  }
  .property-dtl-tab .nav li.active a {
    background: #22c0a1;
    color: #ffffff;
  }
  .property-dtl-tab .nav li.active a::before {
    display: none;
  }
  .property-dtl-tab .tab-content {
    padding: 20px 0 0 0;
  }
  .property-dtl-tab .nav li {
    padding: 0 0px 0 0;
  }
  #tab-ibox a {
    padding: 5px;
  }
  .property-dtl-tab .nav-tabs > li.active > a,
  .property-dtl-tab .nav-tabs > li > a:hover,
  .property-dtl-tab .nav-tabs > li.active > a:hover,
  .property-dtl-tab .nav-tabs > li.active > a:focus {
    background: transparent;
    color: #ffffff;
  }
  .property-dtl-tab .nav-tabs-owner > li.active > a,
  .property-dtl-tab .nav-tabs-owner > li > a:hover,
  .property-dtl-tab .nav-tabs-owner > li.active > a:hover,
  .property-dtl-tab .nav-tabs-owner > li.active > a:focus {
    background: transparent;
    color: #ffffff;
  }
  .summary-box h3 {
    font-size: 20px;
    padding: 0 0 10px 0;
  }
  .summary-box ul li .box p {
    word-wrap: break-word;
  }
  .summary-box ul li {
    width: 50%;
  }
  .summary-box ul li .box {
    margin-bottom: 15px;
  }
  .summary-box {
    padding: 10px 0;
  }
  .neg-left {
    width: auto;
    float: none;
    margin: 0 0 15px 0;
  }
  .neg-left h5 {
    font-size: 18px;
  }
  .property-dtl-tab .tab-content h2 {
    font-size: 20px;
  }
  .coman-modal .modal-content {
    padding: 15px;
  }
  .coman-modal .modal-content::before {
    display: none;
  }
  .coman-modal .modal-content::after {
    display: none;
  }
  .coman-modal .modal-content h2 {
    font-size: 22px;
    padding: 15px 0;
  }
  .dont-ac p {
    font-size: 18px;
  }
  .dont-ac p a {
    font-size: 18px;
  }
  .dont-ac {
    padding: 20px 0 0 0;
  }
  .signup-tab .nav li a {
    font-size: 16px;
  }
  .signup-tab .tab-content {
    padding: 20px 0 0 0;
  }
  .coman-modal .modal-dialog {
    width: auto;
  }
  .agree-box label a {
    display: inline-block;
  }
  .modal h1 {
    font-size: 22px;
  }
  .modal h2 {
    font-size: 18px;
  }
  .property-dtl-tab .nav li a {
    padding: 10px 0;
  }
  .nav.nav-tabs li {
    width: 50%;
  }
  .kyc-section h2 {
    font-size: 28px;
  }
  .kyc-top-bar ul li .box .icon {
    width: 60px;
    height: 60px;
  }
  .kyc-top-bar ul li .box .ic-1,
  .kyc-top-bar ul li .box .ic-2,
  .kyc-top-bar ul li .box .ic-3 {
    background-size: cover;
  }
  .kyc-top-bar ul li .box:before {
    position: absolute;
    top: 50%;
    height: 8px;
    width: calc(100% - 22px);
    margin-top: -4px;
    right: 0px;
  }
  .kyc-status {
    padding: 10px;
  }
  .kyc-status img {
    width: 100px;
  }
  .kyc-status h3 {
    font-size: 20px;
    line-height: 25px;
  }
  .box-heading h2 {
    font-size: 23px;
    line-height: 30px;
    margin-bottom: 0px;
  }
  .nav-tabs > li > a {
    border-radius: 0px;
    margin-right: 0px;
  }
  .nav-tabs-owner > li > a {
    border-radius: 0px;
    margin-right: 0px;
  }
  table.dataTable thead .sorting:after,
  table.dataTable thead .sorting_asc:after,
  table.dataTable thead .sorting_desc:after,
  table.dataTable thead .sorting_asc_disabled:after,
  table.dataTable thead .sorting_desc_disabled:after {
    bottom: 11px !important;
  }
}

@media screen and (max-width: 479px) {
  .banner-box .text {
    left: 55px;
    right: 55px;
  }
  .newly-list .box .text h3 {
    font-size: 16px;
  }
  .newly-list .box .image .price h3 strong {
    font-size: 22px;
  }
  .foot-menu ul li a {
    font-size: 14px;
  }
  .copy-right p {
    font-size: 14px;
  }
  .title-def h2 {
    font-size: 20px;
  }
  .summary-box ul li {
    width: auto;
    float: none;
  }
  .properties-details-sliders .slick-prev {
    left: 15px;
  }
  .properties-details-sliders .slick-next {
    right: 15px;
  }
  .land-info-list ul li {
    float: none;
  }
  .land-info-list ul li .box {
    width: auto;
  }
  .nav.nav-tabs li {
    width: 100%;
  }
  .top-menu2 .top-menu ul li {
    padding: 0px;
  }
  .kyc-status {
    padding: 10px;
  }
  .kyc-status img {
    width: 100px;
  }
  .kyc-status h3 {
    font-size: 20px;
    line-height: 25px;
  }
  .box-heading h2 {
    font-size: 18px;
    line-height: 30px;
  }
}

@media screen and (max-width: 400px) {
  .kyc-top-bar ul li .box .icon {
    width: 50px;
    height: 50px;
  }
  .kyc-top-bar ul li .box .ic-1,
  .kyc-top-bar ul li .box .ic-2,
  .kyc-top-bar ul li .box .ic-3 {
    background-size: cover;
  }
  .kyc-section h2 {
    font-size: 25px;
  }
  .wrapper-content {
    padding: 20px 0px;
  }
  .chk {
    margin: 4px 15px 0 0;
  }
  .kyc-status {
    padding: 10px;
  }
  .kyc-status img {
    width: 100px;
  }
  .kyc-status h3 {
    font-size: 20px;
    line-height: 25px;
  }
  .box-heading h2 {
    font-size: 17px;
    line-height: 30px;
  }
  .table-responsive {
    border: 0px;
  }
  .kyc-section h4 {
    font-size: 17px;
    line-height: 24px;
  }
  .welcome {
    font-size: 13px;
  }
  .nav.nav-tabs li {
    width: 100%;
  }
  .admin-box-heading h2 {
    font-size: 20px;
    margin-bottom: 5px;
  }
  .property-dtl-tab .nav-tabs .ibox-content {
    padding: 15px;
  }
  .top-menu2 .top-menu ul li {
    top: 0px;
  }
  .wallet-list ul li .box h3 {
    font-size: 14px;
    line-height: 22px;
  }
  .ibox-content {
    padding: 10px;
  }
  .faq-list ul li .box p {
    font-size: 15px;
    line-height: 20px;
  }
  .agree-box {
    display: inline-flex;
  }
  .kyc-top-bar ul li .box:before {
    position: absolute;
    top: 50%;
    height: 8px;
    width: calc(100% - -8px);
    margin-top: -4px;
    right: -19px;
  }
}

@media screen and (max-width: 475px) {
  .d-block-mob {
    display: block;
  }
  #history h3 {
    font-size: 16px;
  }
  .success-msg-icon i {
    color: #36ca9a;
    font-size: 63px;
    margin-top: 10px;
    margin-bottom: 20px;
  }
  .coman-modal .modal-content h2 {
    font-size: 16px;
    padding: 15px 0;
  }
  #notification .panel-body {
    padding: 0px;
  }
  .form-group img {
    top: 10px;
  }
  .detail-title-a p {
    flex-wrap: wrap;
  }
  #top-0 .detail-title-a {
    padding-left: 0px;
  }
  .input-group-addon {
    padding: 0px 5px;
  }
  .detail-title-a span {
    width: 100%;
    display: block;
    font-weight: bold;
    vertical-align: text-top;
    padding-right: 20px;
    max-width: 100%;
    min-width: 100%;
  }
  .user-profile {
    margin: 0 auto;
    text-align: center;
  }
  #tab-new1 li {
    width: 100%;
    border-radius: none;
    border-bottom: 1px solid #5c5d5e;
  }
  .tabs-container {
    margin-top: 10px;
  }
  .tabs-container .nav-tabs > li.active > a,
  .tabs-container .nav-tabs > li.active > a:hover,
  .tabs-container .nav-tabs > li.active > a:focus {
    margin-bottom: 0px;
  }
  .tabs-container .nav-tabs-owner > li.active > a,
  .tabs-container .nav-tabs-owner > li.active > a:hover,
  .tabs-container .nav-tabs-owner > li.active > a:focus {
    margin-bottom: 0px;
  }
  .btn-primary.btn-small {
    padding: 8px 14px;
  }
  .min-135 {
    min-width: inherit !important;
  }
  .box-upper .btn-primary,
  .box-upper .btn-default {
    margin-top: 10px;
  }
}

@media (max-width: 767px) {
  .tabs-container .nav-tabs > li.active > a {
    border-bottom: 1px solid #5c5d5e !important;
    margin: 0;
  }
  .tabs-container .nav-tabs-owner > li.active > a {
    border-bottom: 1px solid #5c5d5e !important;
    margin: 0;
  }
  #page-note {
    flex-wrap: wrap;
  }
  #page-note .dataTables_info {
    text-align: center;
  }
  #page-note .text-right {
    text-align: center;
  }
  #tab-costum .nav.nav-tabs li {
    width: 100% !important;
  }
  #color .colours_action ul li {
    min-width: 25% !important;
  }
  .chosen-container {
    width: 100% !important;
  }
  #setting .box-heading h2 {
    margin-bottom: 0px;
  }
  #setting .nav.nav-tabs li {
    width: 100% !important;
  }
  #setting input {
    margin-bottom: 8px;
  }
  #setting label {
    width: 100%;
    margin-bottom: 5px;
  }
  #setting .form-group {
    flex-wrap: wrap;
  }
  #tab-costum .detail-title-a:nth-child(1) {
    padding-top: 0px;
  }
  #add-cat .btn,
  #edit-cat .btn,
  #send-modal .btn {
    width: 100px;
    max-width: 150px;
  }
  #kyc-doc .col-lg-3 {
    margin-bottom: 10px;
  }
  #tab-new1 li {
    width: 100%;
    border-radius: none;
    border-bottom: 1px solid #5c5d5e;
  }
  .tabs-container {
    margin-top: 10px;
  }
  .user-profile {
    text-align: center;
  }
  .tabs-container .nav-tabs > li.active > a,
  .tabs-container .nav-tabs > li.active > a:hover,
  .tabs-container .nav-tabs > li.active > a:focus {
    margin-bottom: 0px;
  }
  .tabs-container .nav-tabs-owner > li.active > a,
  .tabs-container .nav-tabs-owner > li.active > a:hover,
  .tabs-container .nav-tabs-owner > li.active > a:focus {
    margin-bottom: 0px;
  }
  #wrap-a .col-lg-3 {
    padding-left: 0px;
  }
  #wrap-a {
    padding: 0px 15px;
  }
  .nav.navbar-top-links li.dropdown {
    display: inline-block;
  }
  .user-profile img {
    margin-right: 0px;
  }
  #tab-new1 li a {
    height: auto;
  }
}

@media only screen and (max-width: 1360px) and (min-width: 1200px) {
  #sosh-des .detail-title-a span {
    min-width: 156px;
    width: 50%;
    max-width: 200px;
  }
}

@media only screen and (max-width: 1200px) and (min-width: 992px) {
  #sosh-des .detail-title-a span {
    width: 50%;
    display: block;
    font-weight: bold;
    vertical-align: text-top;
    padding-right: 20px;
    max-width: 200px;
    min-width: 200px;
  }
  .detail-title-a p {
    flex-wrap: wrap;
  }
  #restorent-detail .detail-title-a p {
    flex-wrap: nowrap;
  }
  #restorent-detail .detail-title-a span {
    min-width: 145px;
    max-width: 145px;
    width: 100%;
  }
  .slider.round {
    border-radius: 34px;
    width: 42px !important;
    min-width: inherit !important;
  }
}

@media only screen and (max-width: 400px) and (min-width: 320px) {
  .kyc-top-bar ul li .box:before {
    display: none;
  }
  .kyc-top-bar ul li {
    width: 100%;
    margin-top: 15px;
  }
}

@media only screen and (max-width: 992px) and (min-width: 320px) {
  .detail-title-a p {
    flex-wrap: wrap;
  }
  #kyc-doc .box {
    height: 137px;
  }
}

@media only screen and (max-width: 1920px) and (min-width: 1360px) {
  table.dataTable thead .sorting:after,
  table.dataTable thead .sorting_asc:after,
  table.dataTable thead .sorting_desc:after,
  table.dataTable thead .sorting_asc_disabled:after,
  table.dataTable thead .sorting_desc_disabled:after {
    position: absolute;
    bottom: 8px !important;
    right: 8px;
    display: block;
    font-family: 'Glyphicons Halflings';
    opacity: 0.5;
  }
}

@media only screen and (max-width: 576px) and (min-width: 400px) {
  #kyc-doc .box {
    height: 224px;
  }
}

@media only screen and (min-width: 767px) {
  #tab-new1:not(.tab-justify) li {
    max-width: 20%;
  }
}

@media only screen and (max-width: 767px) and (min-width: 577px) {
  #kyc-doc .box {
    height: 338px;
  }
  #logistics-detail .box {
    height: 338px;
    width: 338px;
  }
}

@media only screen and (min-width: 992px) {
  #kyc-doc .box {
    height: 216px;
  }
  #logistics-detail .box {
    height: 216px;
    width: 216px;
  }
}

@media only screen and (min-width: 1200px) {
  #kyc-doc .box {
    height: 160px;
  }
  #logistics-detail .box {
    height: 160px;
    width: 160px;
  }
}

@media only screen and (min-width: 1047px) {
  #tab-new1 li a {
    height: auto;
  }
}

@media screen and (max-width: 320px) {
  .DateRangePicker {
    left: -8px !important;
  }
}

@media screen and (max-width: 360px) {
  .ck.ck-toolbar-dropdown .ck.ck-toolbar .ck.ck-toolbar__items {
    flex-wrap: wrap !important;
    width: 200px;
  }
}

@media screen and (max-width: 396px) {
  .coman-modal .btn-primary {
    width: 100px;
    max-width: 100px;
  }
}

@media screen and (max-width: 480px) {
  #tab-new li,
  #tab-new1 li {
    width: 100%;
  }
}

@media screen and (max-width: 615px) {
  .navbar.navbar-static-top {
    height: 97px;
  }
  .navbar-header,
  .nav.navbar-top-links.navbar-right {
    top: -17px;
  }
  .width-150a {
    top: 60px;
  }
  .user-info-box .dropdown-menu {
    min-width: 120px;
  }
  .navbar-top-links .dropdown-menu li a,
  .navbar-top-links .dropdown-menu li button {
    padding: 3px 8px;
  }
}

@media screen and (max-width: 767px) {
  .header-logo-img {
    display: none;
  }
  .header-logo-img-sm {
    display: block;
  }
}

@media only screen and (max-width: 600px) and (min-width: 320px) {
  .kyc-top-bar ul {
    flex-wrap: wrap !important;
    width: 100%;
  }
}
