.nav > a.active {
  border-left: 4px solid #fff;
  background: #404e67;
}

.metismenu a.active {
  border-left: 4px solid #fff;
  background: #6178a1;
}

.cardwithback-btn,
.btn-no-bg {
  background: transparent;
  border: 0;
  outline: none;
}

.action-btn-color {
  color: #0000ff;
}

.color-black {
  color: #000000;
  background: white;
  border: white;
  font-size: 15px;
  padding: 6px 20px;
  width: 100%;
  text-align: left;
  max-width: 132px;
  border-radius: 3px !important;
}

.dropdown-menu > li > button:focus,
.dropdown-menu > li > button:hover {
  color: #0071ba;
  background-color: #f5f5f5;
  box-shadow: none;
}

.navbar .dropdown-menu {
  padding: 10px;
}

.error-card-div {
  text-align: center;
  font-size: 15px;
  margin: 150px 0px;
}

.padding-left-50 {
  padding-left: 50px !important;
}

.btn-rating,
.btn-rating:hover {
  padding: 5px 10px;
  /* background-color: #0000ff;
  border-color: #0000ff; */
  background-color: #6178a1;
  color: #ffffff;
  margin-right: 5px;
}

.btn-rating i {
  margin-right: 5px;
}

.error-message {
  color: #b90000;
  margin-bottom: 10px;
  margin-top: 5px;
  text-align: center;
}

.left-align {
  text-align: left;
}

.table-image-container {
  width: 100px;
  height: 100px;
  border: 1px solid #5a5959;
}

.table-image-container img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.dateRangePickerWrapper {
  position: relative;
}

.DateRangePicker {
  position: absolute !important;
  z-index: 200;
  background: white;
  top: 40px;
  left: auto;
  border: 2px solid #c3bcbc;
}

.DateRangePickerInput__withBorder {
  border: 1px solid #c3bcbc !important;
}

.DateRangePicker.DateRangePicker_1,
.DateRangePickerInput__withBorder {
  width: 100%;
}

.DateInput__small {
  width: 50% !important;
}

.table-ellipsis {
  width: 350px;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}

@media screen and (max-width: 1200px) {
  .table-ellipsis {
    width: 200px;
  }
}

@media screen and (max-width: 767px) {
  .table-ellipsis {
    width: 100px;
  }
}

.react-tiny-popover-container {
  background: #f2f2f2;
  width: 350px;
  box-shadow: 0 3px 8px rgba(0, 0, 0, 0.3);
  border: 1px solid rgba(0, 0, 0, 0.15);
  border-radius: 3px;
  outline: 0;
  /* padding: 10px; */
}

.table-popover-close,
.table-popover-close:hover,
.table-popover-close:focus {
  color: #ffffff;
}

.table-popover-title {
  background: #626262;
  color: #ffffff;
  text-align: center;
  font-weight: bold;
  padding: 10px;
}

.table-popover-description {
  padding: 10px;
}

.contectus-resolved {
  color: #60c138;
  font-weight: bold;
}

.rating-love .fa.fa-heart,
.rating-love .fa.fa-heart-o {
  color: green;
  margin-right: 1px;
}

.full {
  font-size: 13px;
}

.rating-love {
  position: relative;
  display: inline-block;
}

.rate {
  white-space: nowrap;
  overflow: hidden;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 2;
  margin-top: -9px;
}

.table-responsive {
  overflow-y: hidden;
}

.date-backdrop {
  background: transparent;
  z-index: 199 !important;
}

.css-26l3qy-menu {
  z-index: 201 !important;
}

#order-table td,
#order-table tr,
#order-table th {
  padding: 4px !important;
}

.printview-order-inner.order_details {
  background: #f5f5f6;
  border: 1px solid #ebebeb;
  border-bottom: none;
}

.action-btn i.fa-close {
  padding: 5px 6px;
}

.padding-left-15 {
  padding-left: 15px !important;
}

.text-align-left {
  text-align: left;
}

.m-5 {
  margin: 5px;
}

.cke_dialog td {
  max-width: unset !important;
}

input[type='text']:disabled {
  color: grey;
}

.date-picker-error {
  position: relative;
  top: 50px;
  text-align: left;
}

.mt-coupon-status {
  margin-top: 45px;
}

@media (max-width: 374px) {
  .mt-coupon-status {
    margin-top: 55px;
  }
}

.dateRangePickerWrapper .DateRangePicker {
  top: unset;
  left: unset;
}

.DateInput_input {
  line-height: 20px !important;
}

.CalendarDay__selected_span {
  background: #82e0aa;
  color: white;
  border: 1px solid lightcoral;
}

.CalendarDay__selected {
  background: darkred;
  color: white;
}

.CalendarDay__selected:hover {
  background: orange;
  color: white;
}

.CalendarDay__hovered_span:hover,
.CalendarDay__hovered_span {
  background: brown;
}

.order-details-color {
  background: #e8fff8 !important;
}

li.react-datepicker__time-list-item {
  min-width: 100% !important;
  font-size: 14px;
}

.hidden-file-uploder {
  position: absolute;
  width: 100%;
  height: 100%;
  opacity: 0;
  z-index: 10;
  cursor: pointer;
}

.react-datepicker-popper {
  z-index: 11 !important;
}

.upload-remove {
  /* position: inherit !important;
  z-index: 1;
  right: 0px;
  top: 6px !important;
  padding: 6px;
  font-size: 22px !important;
  background: #d20d0d;
  width: 28px;
  color: #fff !important;
  border-radius: 3px; */
  position: absolute;
  z-index: 1;
  top: -16px !important;
  padding: 5px;
  font-size: 18px !important;
  background: #ce0101;
  width: 28px;
  color: #ffffff !important;
  border-radius: 50px;
  margin-left: 35px;
  right: -15px;
}

.mt-10 {
  margin-top: 10px;
}

.photo-preview {
  width: 50%;
  margin-top: 10px;
  /* display: block; */
  margin-left: auto;
  margin-right: auto;
}

li.dropdown.m-r-sm {
  margin: 0;
}

li.dropdown.m-r-sm a {
  margin-top: 10px;
}

.clockpicker .input-group-addon {
  padding: 10px 13px;
}

.react-datepicker-wrapper {
  width: 100%;
}

.react-datepicker__input-container {
  height: 35px !important;
}

.ck.ck-dropdown .ck-dropdown__panel.ck-dropdown__panel_se,
.ck.ck-dropdown .ck-dropdown__panel.ck-dropdown__panel_sw {
  top: -15% !important;
}

.DateRangePickerInput_arrow.DateRangePickerInput_arrow_1 {
  display: none;
}

.DateInput_input__small {
  border-bottom: none !important;
}

input#startDateId {
  border-right: 1px solid #c3bcbc;
}

.pagination > .active > a:focus {
  outline: none;
}

.profile-under-review {
  text-align: center;
}

.plr-5 {
  padding-left: 11px !important;
  padding-right: 11px !important;
}

.react-month-picker {
  max-width: 300px;
}
.react-month-picker .calendar-container {
  right: auto;
  left: 0;
}
.react-month-picker .selected_cell,
.react-month-picker .col_mp:hover {
  background-color: #0000ff !important;
  color: #fff !important;
}

.react-month-picker .selected_date_mp {
  font-size: 16px;
}

.react-month-picker .selected_date_mp {
  color: #0000ff !important;
}
.instantDelivery {
  height: 21px !important;
  margin-top: 9px !important;
}

.pagination > li > a:focus,
.pagination > li > a:hover,
.pagination > li > span:focus,
.pagination > li > span:hover {
  border-color: #0171ba;
}

.card {
  position: relative;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  min-width: 0;
  word-wrap: break-word;
  background-color: #fff;
  background-clip: border-box;
  border: 1px solid #c3bcbc;
  border-radius: 0.25rem;
  padding: 10px;
}

.required-field::after {
  content: '*';
  color: red;
}

.fa-spin {
  -webkit-animation: fa-spin 2s linear infinite;
  animation: fa-spin 2s linear infinite;
}
